@font-face {
    font-family: 'FranklinGothic-Book';
    src: url('fonts/FranklinGothic-Book.eot');
    src: url('fonts/FranklinGothic-Book.eot?#iefix') format('embedded-opentype'),
         url('fonts/FranklinGothic-Book.woff') format('woff'),
         url('fonts/FranklinGothic-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FranklinGothic-Book';
    src: url('fonts/FranklinGothicMediumC.eot');
    src: url('fonts/FranklinGothicMediumC.eot?#iefix') format('embedded-opentype'),
         url('fonts/FranklinGothicMediumC.woff') format('woff'),
         url('fonts/FranklinGothicMediumC.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    list-style: none;
}


.container_main.App .marquiz__button_fixed {
    height: 36px;
    line-height: 36px;
    font-family: 'FranklinGothic-Book'!important;
    font-weight: 100;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0px;
}
.container_main.App .marquiz__button_fixed .icon-quiz {
    height: 22px;
    width: 22px;
    padding: 4px 2px 4px 2px;
}
.container_main.App{    
    font-family: 'FranklinGothic-Book';
    text-align: left;
    min-width: 320px;
    overflow: hidden;
}
#root{
    position: relative;
    z-index: 1;
}
.clr{
    clear: both;
}
.modal-open {
    overflow: hidden;
}
.overflow {
    overflow: hidden;
}
.Mui-error:before {
    border-bottom: 2px solid rgb(255, 0, 0)!important;
    transition: .3s;
}
.Mui-error{
    color: rgba(255, 0, 0)!important;
    transition: .3s;
}

.cbk-phone.callbackkiller-mobile, .cbk-phone.callbackkiller-mobile:hover {
    top: auto!important;
    bottom: 100px!important;
    right: 196px!important;
}
/* menu */
.menu{
    z-index: 1;
}
header.top_bar{
    box-shadow: none;
    background: none;
    transition: .3s;
}
.menu .ct_main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    width: 100%;
    padding: 0;
}
.ct_main{
    display: block;
    width: 100%;
    max-width: 1430px;
    margin: 0 auto;
    font-size: 0;
    box-sizing: border-box;
}
.menu_sandwich{
    display: none;
}
.menu_closeform{
    display: none;
}
logo{
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-top: 26px;
    transition: .3s;
}
logo:before {
    display: block;
    position: absolute;
    content: '';
    width: 2px;
    height: 1px;
    background: initial;
    box-shadow: 0px 0px 110px 170px rgb(255 255 255 / 80%);
    top: 50%;
    left: 50%;
    opacity: 1;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    z-index: 1;
    transition: .3s;
}
logo img{
    display: block;
    position: relative;
    z-index: 2;
    width: 140px;
    transition: .3s;
}

.nav_menu{
    display: inline-block;
    position: relative;
    vertical-align: top;
    padding-top: 36px;
    /* margin-left: 160px; */
    z-index: 2;
    transition: 0.3s;
}
.nav_menu a{
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
    padding: 0 3px;
    cursor: pointer;
    transition: .3s;
}
.nav_menu a:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 0px;
    background: #2fafc1;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: .3s;
}
.nav_menu a:hover:after{
    height: 6px;
    transition: .3s;
}
.nav_menu a span{
    display: block;
    position: relative;
    font-size: 16px;
    z-index: 3;
}
.nav_menu a + a{
    margin-left: 33px;
}

.menu_phone{
    display: inline-block;
    vertical-align: top;
    position: relative;
    /* width: 250px; */
    transition: .3s;
    z-index: 1;
}
.menu_phone:before {
    display: block;
    position: absolute;
    content: '';
    width: 2px;
    height: 1px;
    background: initial;
    box-shadow: 0px 0px 110px 180px rgb(255 255 255 / 80%);
    top: 50%;
    left: 36%;
    opacity: 1;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: .3s;
    z-index: 1;
}
.mp_wrap{
    display: block;
    position: relative;
    width: 100%;
    /* background: #168291; */
    padding-top: 24px;
    padding-bottom: 18px;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    padding-left: 20px;
    transition: .3s;
}
.mp_number{
    display: inline-block;
    position: relative;
    vertical-align: top;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    transition: .3s;
    line-height: 1;
}
.mp_number:before{
    display: block;
    position: absolute;
    content: '';
    background: url(../images/mp_number_ico.svg)no-repeat center center;
    background-size: 100%;
    width: 21px;
    height: 21px;
    left: -36px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
}
.mp_number a{
    color: #13646f;
    text-decoration: none;
    font-weight: 500;
    line-height: 36px;
    margin-right: 44px;
}
.mp_btn {
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    color: #fff;
    background: #168291;
    text-decoration: none;
    font-size: 15px;
    width: 200px;
    line-height: 36px;
    margin-top: 0;
    transition: .3s;
}
.mp_btn:hover{
    text-decoration: none;
}
.mp_loc{
    display: block;
    position: relative;
    text-align: right;
    padding-right: 4px;
    padding-top: 16px;
    transition: .3s;
    z-index: 2;
}
.mp_loc span{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 18px;
    color: #1f2225;
    transition: .3s;
    line-height: 1;
}
.mp_loc span:before{
    display: block;
    position: absolute;
    content: '';
    background: url(../images/mp_loc_ico.svg)no-repeat center center;
    width: 21px;
    height: 27px;
    left: -28px;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s;
}






.menu.menu_scrool  logo{
    padding-top: 4px;
    padding-bottom: 3px;
}
.menu.menu_scrool logo:before{
    opacity: 0;
}
.menu.menu_scrool logo img{
    width: 80px;
}
.menu.menu_scrool .nav_menu{
    padding-top: 20px;
    margin-left: 60px;
}
.menu.menu_scrool .nav_menu a {
    color: #2f2c2c;
}
.menu.menu_scrool .menu_phone:before{
    opacity: 0;
}
.menu.menu_scrool .mp_wrap{
    padding-top: 10px;
    padding-bottom: 10px;
}
.menu.menu_scrool header.top_bar{
    background: rgba(255,255,255, .9);
}
.menu.menu_scrool .mp_loc{
    margin-top: -18px;
    padding-top: 0;
    opacity: 0;
    pointer-events: none;
}





/* menu */



/* main_screen */
.head_plashka {
    display: block;
    position: absolute;
    left: 162px;
    top: -158px;
}
.main_screen{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
    height: 100vh;
    min-height: 550px;
    background: url(../images/main_screen_bg1.jpg)no-repeat center top;
    background-size: cover;
    flex-direction: column;
    padding-top: 170px;
    padding-bottom: 170px;
}
.main_screen:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.1);
}
.main_screen_inner {
    position: relative;
    z-index: 20;
    width: 100%;
}
.ms_inner{
    display: block;
    position: relative;
    z-index: 2;
    padding-bottom: 200px;
}
h1{
    display: inline-block;
    position: relative;
    font-size: 85px;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
}
h1:before {
    display: block;
    position: absolute;
    content: '';
    background: url(../images/text_dec.svg)no-repeat center center;
    width: 51px;
    height: 38px;
    left: -13px;
    top: -24px;
    z-index: 2;
}
sub{
    display: block;
    font-size: 26px;
    color: #65dff0;
    line-height: 1;
    margin-top: 20px;
}
sub span {
    display: block;
    font-size: 26px;
    line-height: 130%;
}
sub span + span {
    margin-top: 15px;
}
.apart_info_box{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 212px;
    height: 190px;
    background: #1f98a9;
    margin-top: 24px;
}
.apart_info_box span{
    display: block;
    color: #2ddbf4;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
}
.apart_info_box b{
    font-weight: normal;
    font-size: 72px;
    line-height: 1;
    color: #fff;
}
.apart_info_box l{
display: block;
color: #fff;
font-size: 20px;
}


.ms_list{
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}
.msl_el{
    display: inline-block;
    vertical-align: top;
    padding-bottom: 68px;
}
.msl_el + .msl_el{
    margin-left: 44px;
}
.msl_el img{
    display: inline-block;
    vertical-align: middle;
    width: 34px;
}
.msl_el span{
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
}
.project_financing{
    display: block;
    position: absolute;
    width: 310px;
    height: 88px;
    background: url(../images/project_financing.jpg)no-repeat center center;
    background-size: 100%;
    right: 0;
    bottom: 0;
}
.urban_ico{
    display: block;
    position: absolute;
    width: 123px;
    left: 0;
    top: -190px;
}
.next_bl_ar{
    display: block;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 14px;
    background: url(../images/next_bl_ar.svg)no-repeat center center;
    background-size: 100%;
}
/* main_screen */



.excurs .title_main {
    font-size: 67px;
}


/* special_offer */
.special_offer{
    display: block;
    padding-top: 140px;
}
.special_offer .ct_main{
    position: relative;
}
.special_offer .title_main p{
    font-size: 30px;
}
.special_offer .pu_form{
    max-width: 432px;
    margin-top: -30px;
}
.special_offer .form_title {
    font-size: 30px;
    line-height: 120%;
    text-transform: uppercase;
    /* color: #C4AF98; */
    color: #168291;
    padding-bottom: 50px;
}
.special_offer .form_title br {
    display: none;
}
.so_img{
    display: block;
    position: absolute;
    max-width: 940px;
    right: 0;
    top: 40px;
}
.so_img img{
    display: block;
    max-width: 100%;
}

.special_offer .title_main span {
    font-size: 150%;
}

.so_in {
    position: relative;
    padding-top: 60px;
    padding-bottom: 230px;
}
.so_slid {
    margin-top: -40px;
}
.so_slid .slick-next, .so_slid .slick-prev {
    bottom: -20px;
}
.so_slid p{
    display: block;
    position: relative;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1;
    color: #168291;
}

/* special_offer */





/* advantages_house */
.advantages_house{
    display: block;
    position: relative;
    padding-top: 144px;
}
.title_main {
    display: block;
    position: relative;
    color: #1f2225;
    font-size: 45px;
    line-height: 1;
    text-transform: uppercase;
    padding-left: 30px;
    left: -30px;
    box-sizing: border-box;
}
.title_main span{
    display: inline-block;
    position: relative;
}
.title_main span:before {
    display: block;
    position: absolute;
    content: '';
    background: url(../images/text_dec.svg)no-repeat center center;
    width: 51px;
    height: 38px;
    left: -29px;
    top: -28px;
    z-index: 2;
}
.title_main p{
    display: block;
    position: relative;
    font-size: 24px;
    margin-top: 18px;
    line-height: 1;
    color: #168291;
}

.ah_list{
    display: block;
    margin-top: 50px;
    background: url(../images/ah_1_bg.jpg)no-repeat center top;
    background-size: cover;
    background-position: center top!important;
    transition: 0.3s;
}
.ah_list .ct_main{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.ah_card{
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 25%;
    height: 380px;
    transition: .3s;
    background: none;
    box-sizing: border-box;
    padding-left: 70px;
    border-bottom: 1px solid rgb(255, 255, 255, .3);
    border-right: 1px solid rgb(255, 255, 255, .3);
}
.ah_card:hover{
    background: rgb(31, 152, 169, .9);
    border: none;
}
.ah_card+.ah_card+.ah_card+.ah_card+.ah_card{
    border-bottom: none;
}
.ah_card span{
    display: block;
    position: relative;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 40px;
    transition: .3s;
    opacity: 0;
    top: 10px;
}
.ah_card:hover span{
    opacity: 1;
    top: 0;
}
.ahc_img{
    display: block;
    position: relative;
    width: 90px;
    height: 90px;
    left: -24px;
    transition: .3s;
}
.ahc_img:before{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(255, 255, 255, .9);
    transform: scale(1);
    transition: .3s;
    z-index: 0;
}
.ah_card:hover .ahc_img:before{
    transform: scale(0);
}
.ahc_img:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    transition: .3s;
}

.ah1 .ahc_img:after{
    background:  url(../images/ah_ico1.svg)no-repeat center center;
    background-size: 43px;
}
.ah1:hover .ahc_img:after{
    background:  url(../images/ah_ico1act.svg)no-repeat center center;
    background-size: 43px;
}


.ah2 .ahc_img:after{
    background:  url(../images/ah_ico2.svg)no-repeat center center;
    background-size: 43px;
}
.ah2:hover .ahc_img:after{
    background:  url(../images/ah_ico2act.svg)no-repeat center center;
    background-size: 43px;
}


.ah3 .ahc_img:after{
    background:  url(../images/ah_ico3.svg)no-repeat center center;
    background-size: 43px;
}
.ah3:hover .ahc_img:after{
    background:  url(../images/ah_ico3act.svg)no-repeat center center;
    background-size: 43px;
}


.ah4 .ahc_img:after{
    background:  url(../images/ah_ico4.svg)no-repeat center center;
    background-size: 43px;
}
.ah4:hover .ahc_img:after{
    background:  url(../images/ah_ico4act.svg)no-repeat center center;
    background-size: 43px;
}
.ah4{
    border-right: none;
}

.ah5 .ahc_img:after{
    background:  url(../images/ah_ico5.svg)no-repeat center center;
    background-size: 37px;
}
.ah5:hover .ahc_img:after{
    background:  url(../images/ah_ico5act.svg)no-repeat center center;
    background-size: 37px;
}

.ah6 .ahc_img:after{
    background:  url(../images/ah_ico6.svg)no-repeat center center;
    background-size: 30px;
}
.ah6:hover .ahc_img:after{
    background:  url(../images/ah_ico6act.svg)no-repeat center center;
    background-size: 30px;
}

.ah7 .ahc_img:after{
    background:  url(../images/ah_ico7.svg)no-repeat center center;
    background-size: 28px;
}
.ah7:hover .ahc_img:after{
    background:  url(../images/ah_ico7act.svg)no-repeat center center;
    background-size: 28px;
}


.ah8 .ahc_img:after{
    background:  url(../images/ah_ico8.svg)no-repeat center center;
    background-size: 43px;
}
.ah8:hover .ahc_img:after{
    background:  url(../images/ah_ico8act.svg)no-repeat center center;
    background-size: 43px;
}
.ah8{
    border-right: none;
}
/* advantages_house */


/* large_yard */
.large_yard{
    display: block;
    position: relative;
    padding-top: 190px;
}
.large_yard .ct_main{
    font-size: 0;
}
.large_yard .title_main{
    display: inline-block;
    vertical-align: top;
    width: 50%;
}
.ly_sub_tit{
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1;
    color: #168291;
    margin-top: 20px;
}
.ly_title_info{
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 50%;
    padding-left: 230px;
    padding-top: 40px;
    padding-bottom: 40px;
    box-sizing: border-box;
}
.ly_title_info:before{
    display: block;
    position: absolute;
    content: '';
    background: url(../images/ly_tit_ico.png)no-repeat  center center;
    background-size: 100%;
    width: 256px;
    height: 219px;
    left: -46px;
    top: -50px;
    z-index: 2;
}
.ly_title_info:after{
    display: block;
    position: absolute;
    content: '';
    width: 50vw;
    left: 0;
    top: 0;
    height: 100%;
    background: #168291;
}
.ly_title_info span{
    display: block;
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 18px;
}
.ly_plan{
    display: block;
    position: relative;
    width: 100%;
}
.ly_plan img{
    display: block;
    width: 100%;
    z-index: 1;
}
.ly_ico{
    display: block;
    position: absolute;
    z-index: 3;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e7cdb2;
    transition: 0.3s;
}
.ly_ico:hover{
    background-color: #1f98a9;
}
.ly_img_circle{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: 50%;
    background: inherit;
}
.ly_ico:before{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color:inherit;
    transform: scale(1.2);
    transition: 0.1s;
    opacity: .5;
    z-index: 2;
}
.ly_ico:after{
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color:inherit;
    transform: scale(1.4);
    transition: 0.1s;
    opacity: .25;
    z-index: 2;
}
.ly_ico img{
    display: block;
    position: relative;
    z-index: 4;
}
.ly_ico span{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    width: 185px;
    height: 48px;
    box-sizing: border-box;
    background: #fff;
    left: 50%;
    top: 0;
    text-align: center;
    padding-left: 30px;
    font-size: 12px;
    color: #1f2225;
    text-transform: uppercase;
    transform-origin: center left;
    transform: scaleX(0);
    opacity: 0;
    transition: 0.3s;
    z-index: 1;
}
.ly_ico:hover span{
    transform: scaleX(1);
    opacity: 1;
}
.summerhouse {
    top: 50%;
    right: 18%;
}
.summerhouse img{
    width: 20px;
}
.sport {
    top: 48%;
    right: 41%;
}
.sport img{
    width: 28px;
}

.pet {
    top: 54%;
    right: 27%;
}
.pet img{
    width: 26px;
}
.walking_area {
    top: 62%;
    right: 43%;
}
.walking_area img{
    width: 31px;
}

.kids {
    top: 90%;
    right: 38%;
}
.kids img{
    width: 30px;
}

.park {
    top: 12%;
    right: 24%;
}
.park img{
    width: 28px;
}
.parking {
    top: 26%;
    left: 34%;
}
.parking img{
    width: 34px;
}

.no_dark_places{
    display: block;
    position: absolute;
    bottom: 0;
    left: 23%;
}
.no_dark_places span{
    display: block;
    position: relative;
    width: 378px;
    background: #e7cdb2;
    font-size: 22px;
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    padding-left: 74px;
    line-height: 99px;
    color: #1f2225;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
}
.no_dark_places span:before{
    display: block;
    position: absolute;
    content: '';
    width: 237px;
    height: 238px;
    background: url(../images/no_dark_places.png)no-repeat center top;
    top: 50%;
    left: -166px;
    transform: translateY(-50%);
}


.ndp_sub_text {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    left: 100%;
    top: 0;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    color: #1f2225;
    text-transform: uppercase;
    transform-origin: center left;
    transform: scaleX(0);
    opacity: 0;
    transition: 0.3s;
    z-index: 3;
}
.no_dark_places:hover .ndp_sub_text {
    transform: scaleX(1);
    opacity: 1;
}
.no_dark_places:hover span{
    background-color: #1f98a9;
    color: #fff;
}
/* large_yard */

/* loc */
.loc{
    display: block;
    position: relative;
    padding-top: 160px;
    padding-bottom: 130px;
}
.loc_in{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    vertical-align: top;
    width: 700px;
    margin-top: 60px;
    float: left;
}
.loc_in+.loc_in{
    float: right;
    flex-direction: column-reverse;
}
.loc_img {
    display: block;
    width: 100%;
}
.loc_img img{
    display: block;
    width: 100%;
}
.loc_in p{
    display: block;
    width: 100%;
    font-size: 24px;
    color: #1f2225;
    line-height: 1;
    padding: 80px 30px;
    box-sizing: border-box;
}
.loc_in p span{
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 21px;
    color: #168291;
    line-height: 1;
    padding-bottom: 20px;
}
.loc .title_main br{
    display: none;
}
/* loc */

/* excurs */
.excurs{
    display: block;
    position: relative;
    background: url(../images/exs_bg.jpg)no-repeat center center;
    background-size: cover;
}
.excurs .title_main{
    color: #fff;
}
button{
    font-family: 'FranklinGothic-Book';
    font-weight: normal;
    background: none;
    cursor: pointer;
    border: none;
    padding: 0;
    transition: .3s;
}
button:focus {
    outline: 0px;
}
.ex_btn{
    display: block;
    font-size: 18px;
    background: #e7cdb2;
    color: #168291;
    line-height: 1;
    text-transform: uppercase;
    width: 272px;
    text-align: center;
    border: 2px solid #e7cdb2;
    line-height: 46px;
    margin-top: 18px;
}
.ex_btn:hover{
    color: #fff;    
    border: 2px solid #fff;
    background: none;
}
.excurs .ct_main{
    position: relative;
    padding-top: 64px;
    padding-bottom: 24px;
}
.excurs .ct_main:before{
    display: block;
    position: absolute;
    content: '';
    background: url(../images/ex_dec.png) no-repeat center center;
    background-size: cover;
    width: 525px;
    height: 320px;
    bottom: 0;
    right: 0px;
    z-index: 2;
}
/* excurs */

/* transport */
.transport{
    display: block;
    padding-top: 130px;
    padding-bottom: 56px;
}
.transport_list{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    font-size: 0;
    margin-top: 70px;
}
.tl_in_main{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 266px;
    height: 140px;
    background: #e5cbb0;
    box-sizing: border-box;
}
.tl_ar_dec{
    display: block;
    position: absolute;
    right: -112px;
    top: 0;
}
.tl_in_main .tl_ar_dec {
    border: 70px solid transparent;
    border-left: 42px solid #e5cbb0;
}
.tl_ar_dec {
    border: 70px solid transparent;
    border-left: 42px solid #f3f5f7;
}
/* .tl_in_main:before{
    display: block;
    position: absolute;
    content: '';
    width: 261px;
    height: 90px;
    background: url(../images/tl_in_main_dec.png)no-repeat center center;
    background-size: 100%;
    left: 0;
    top: -48px;
} */
.tl_in_main span{
    display: block;
    color: #168291;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    padding-top: 30px;
    text-align: center;
}
.tl_in {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    min-width: 128px;
    height: 140px;
    background: #f3f5f7;
    margin-left: 41px;
    padding: 0 2px;
}
.tl_in+.tl_in{
    margin-left: 46px;
}
.tl_in img{
    display: block;
    width: 30px;
}
.tl_in span{
    display: block;
    margin-top: 12px;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    color: #1f2225;
}
.tl_in b{
    display: block;
    text-transform: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    color: #168291;
}
.tl_in:before {
    display: block;
    position: absolute;
    content: '';
    border-right: 40px solid #f3f5f7;
    border-bottom: 70px solid transparent;
    top: 0;
    left: -40px;
}
.tl_in:after{
    display: block;
    position: absolute;
    content: '';
    border-right: 40px solid #f3f5f7;
    border-top: 70px solid transparent;
    bottom: 0;
    left: -40px;
}

.bus_stop{
    display: block;
    position: relative;
    width: 300px;
    padding-left: 121px;
    box-sizing: border-box;
    text-transform: uppercase;
    text-align-last: left;
    font-size: 16px;
    line-height: 1;
    color: #168291;
    margin: 0 auto;
    margin-top: 60px;
}
.bus_stop:before{
    display: block;
    position: absolute;
    content: '';
    width: 177px;
    height: 93px;
    background: url(../images/trp_btn_ico.png)no-repeat center top;
    background-size: 100%;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events:none;
}
.car_dec {
    display: block;
    position: absolute;
    width: 257px;
    height: 90px;
    background: url(../images/tl_in_main_dec.png)no-repeat center center;
    background-size: 100%;
    left: 14px;
    top: -53px;
    z-index: 1;
    pointer-events: none;
    -webkit-transition: 2.5s;
    -moz-transition: 2.5s;
    -ms-transition: 2.5s;
    -o-transition: 2.5s;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -ms-transition-timing-function: ease;
    -o-transition-timing-function: ease;
}
.move .car_dec {
    left: calc(100% - 270px);
}
/* transport */


/* infra */
.infra{
    display: block;
    position: relative;
    padding-top: 133px;
    padding-bottom: 124px;
    background: url(../images/infra_bg.jpg)no-repeat center center;
    background-size: cover;
}
.infra .title_main{
    color: #fff;
    padding-left: 0;
    left: 0;
}
.infra .ct_main{
    /* padding-left: 30px; */
}
.infra_text{
    display: block;
    font-size: 20px;
    color: #e7cdb2;
    line-height: 1;
    margin-top: 46px;
}

.infra_list{
    display: flex;
    align-items: start;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 104px;
}
.infra_in{
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 33.3%;
    text-align: left;
}
.infra_in+.infra_in+.infra_in+.infra_in{
    margin-top: 70px;
}
.infra_in img{
    display: block;
    height: 47px;
}
.infra_in span{
    display: block;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1;
    margin-top: 32px;
}
/* infra */

/* plan_price */
.plan_price{
    padding-top: 118px;
    padding-bottom: 160px;
}
.plan_price .title_main{
    left: 0;
}
.download_plan{
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 314px;
    text-transform: uppercase;
    text-align-last: left;
    font-size: 16px;
    line-height: 1;
    color: #168291;
    text-decoration: underline #168291;
    transition: 0.3s;
    margin-left: 184px;
    margin-top: 18px;
}
.download_plan:hover{
    text-decoration: none;
}
.download_plan:before{
    display: block;
    position: absolute;
    content: '';
    width: 177px;
    height: 110px;
    background: url(../images/download_plan_ico.png)no-repeat center top;
    background-size: 100%;
    left: -154px;
    top: 84%;
    transform: translateY(-50%);
    pointer-events:none;
}
.plan_nav{
    display: block;
    text-align: left;
    font-size: 0;
    padding-left: 30px;
    box-sizing: border-box;
    margin-top: 60px;
}
.plan_nav a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 16px;
    color: #1b1b1b;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    transition: .3s;
}
.plan_nav a:hover{
    color: #e7cdb2;
}
.plan_nav a span{
    display: block;
    position: relative;
    padding: 0 7px;
    z-index: 2;
}
.plan_nav a:before{
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    opacity: 1;
    bottom: -2px;
    left: 7px;
    right: 7px;
    background: #626262;
    transition: .3s;
    z-index: 1;
}
.plan_nav a:hover:before{
    opacity: 0;
}
.plan_nav a.act:before{
    opacity: 1;
    left: 0;
    right: 0;
    background: #168291;
    bottom: -3px;
    height: 8px;
}
.plan_nav a+a{
    margin-left: 22px;
}
.plan_slid{
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin-top: 42px;
}
.container_main.App .slick-next, .container_main.App .slick-prev{
    width: 69px;
    height: 20px;
    top: auto;
    transform: none;
    transition: 0.3s;
}
.plan_slid  .slick-next, .plan_slid  .slick-prev{
    bottom: -50px;
}
.container_main.App .slick-next:before, .container_main.App .slick-prev:before{
    content: ''!important;
}
.container_main.App .slick-next{
    background: url(../images/slid_ar_next.png);
    z-index: 111;
    right: 48.5%;
    transform: translateX(100%);
}
.container_main.App .slick-prev{
    background: url(../images/slid_ar_prev.png);
    z-index: 111;
    left: 48.5%;
    transform: translateX(-100%);
}


.container_main.App .slick-next:hover{
    background: url(../images/slid_ar_next_hov.png);
}
.container_main.App .slick-prev:hover{
    background: url(../images/slid_ar_prev_hov.png);
}
.ps_inn{
    display: flex!important;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    width: 330px!important;
    box-sizing: border-box;
    border: 1px solid rgb(227, 227, 227, 0);
    padding: 26px 32px 24px 32px;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s;
}
.ps_inn:hover{
    border: 1px solid rgb(227, 227, 227, 1);
}
.ps_inn .ps_btn {
    background: #168291;
    color: #fff;
    transition: 0.4s;
}
.ps_inn:hover .ps_btn {
    color: #168291 !important;
    background: transparent !important;
    border: 1px solid #168291 !important;
}
.ps_img{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 242px;
}
.ps_img img{
    display: block;
    max-height: 242px;
    max-width: 100%;
}
.room_num{
    display: block;
    position: relative;
    margin-top: 20px;
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1;
    color: #1b1b1b;
}
.room_num:after{
    display: block;
    position: relative;
    content: '';
    width: 100%;
    height: 1px;
    background: #dddddd;
    margin-top: 16px;
}
.ps_area{
    display: flex;
    align-items: start;
    align-content: center;
    justify-content: start;
    width: 100%;
    margin-top: 14px;
    text-align: left;
}
.ps_area span{
    font-size: 10px;
    text-transform: uppercase;
    color: #d3d3d3;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
}
.ps_area b{
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    font-size: 22px;
    color: #1b1b1b;
    line-height: .8;
    margin-left: 24px;
}
.ps_area b sup{
    font-size: 12px;
    text-transform: uppercase;
    position: relative;
    top: 2px;
    margin-left: 3px;
}
.ps_btn{
    display: block;
    width: 100%;
    margin-top: 22px;
    text-transform: uppercase;
    line-height: 36px;
    font-size: 14px;
    color: #168291;
    background: none;
    border: 1px solid #168291;
    transition: 0.3s;
}
.ps_btn:hover{
    background: #168291;
    color: #fff;
}
.ps_inn:hover .ps_btn{
    background: #168291;
    color: #fff;
}
/* plan_price */

/* fin_apartments */
.fin_apartments{
    display: block;
    position: relative;
    padding-bottom: 80px;
    background: #f9fbfd;
    overflow: hidden;
}
.fin_apartments .ct_main{
    display: block;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    position: relative;
    z-index: 3;
}
.fin_apartments .title_main{
    display: flex;
    flex-direction: column;
    padding-top: 80px;
}
.fin_sub_tit{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    color: #168291;
    margin-top: 66px;
    padding-left: 100px;
}
.fin_sub_tit:before{
    display: block;
    position: absolute;
    content: '';
    width: 114px;
    height: 117px;
    background: url(../images/fin_sub_ico.png)no-repeat center left;
    top: 50%;
    transform: translateY(-50%);
    left: -28px;
}


.fs_btn{
    display: block;
    width: 290px;
    line-height: 54px;
    font-size: 16px;
    text-transform: uppercase;
    background: #168291;
    color: #fff;
    border: 1px solid #168291;
    text-align: center;
    /* font-weight: 500; */
    margin-top: 34px;
    /* margin-left: 36px; */
    cursor: pointer;
    transition: 0.3s;
}
.fs_btn span:before{
    display: none;
}
.fs_btn:hover{
    background: none;
    color: #168291;
}

.fin_sub_list{
    display: block;
    position: relative;
    /* padding-left: 30px; */
    margin-top: 30px !important;
}
.fin_sub_list li span{
    display: block;
    position: relative;
    font-size: 20px;
    color: #1f2225;
    padding-left: 40px;
    box-sizing: border-box;
    text-transform: uppercase;
    margin-top:18px;
}
.fin_sub_list li span:before {
    display: block;
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: url(../images/cpi_ico.svg)no-repeat center center;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
}

/*.fin_sub_list:before{
    display: block;
    position: absolute;
    content: '';
    width: 114px;
    height: 117px;
    background: url(../images/fin_sub_ico.png)no-repeat center left;
    top: auto;
    bottom: -40px;
    right: 40px;
}*/
.wb_btn{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.wb_btn img{
    display: block;
    width: 100%;
}

.wb_btn span{
    display: inline-block;
    position: absolute;
    /* left: 68px; */
    /* bottom: 50px; */
    text-decoration: underline #1db4c8;
    font-size: 18px;
    color: #1db4c8;
    transition: 0.3s;
    padding-left: 10px;
}
.wb_btn:hover span{
    text-decoration: none;
}
.wb_btn span:before{
    display: block;
    position: absolute;
    content: '?';
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    background: #20b1c2;
    top: 50%;
    right: -33px;
    transform: translateY(-50%);
}

.fin_variants{
    display: flex;
    flex-wrap: wrap;
    width: 820px;
}
.fin_in{
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 400px;
    height: 222px;
    padding: 32px 44px;
    box-sizing: border-box;
    cursor: pointer;
}
.fin_in span{
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    text-transform: uppercase;
}
.fin_in a{
    display: inline-block;
    position: relative;
    margin-top: 18px;
    text-decoration: underline #1db4c8;
    font-size: 18px;
    color: #1db4c8;
    transition: 0.3s;
}
.fin_in:hover a{
    text-decoration: none;
}
.fin_in a:before{
    display: block;
    position: absolute;
    content: '?';
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    background: #20b1c2;
    top: 50%;
    right: -33px;
    transform: translateY(-50%);
}
.pre_final{
    background: url(../images/pre_final_bg.jpg)no-repeat center center;
    background-size: cover;
}
.final{
    background: url(../images/final_bg.jpg)no-repeat center center;
    background-size: cover;
    margin-left: 20px;
}
.repair_apart{
    background: url(../images/repair_apart_bg.jpg)no-repeat center center;
    background-size: cover;
    margin-top: 20px;
}
/* fin_apartments */

/* get_flat */
.get_flat{
    display: block;
    position: relative;
    z-index: 1;
    padding-top: 172px;
    padding-bottom: 84px;
    background: url(../images/get_flat_bg.jpg)no-repeat center center;
    background-size: cover;
    /* margin-top: -64px; */
}
.get_flat .title_main{
    color: #fff;
}

.gf_sub_tit{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    color: #e7cdb2;
    margin-top: 62px;
    padding-left: 100px;
}
.gf_sub_tit:before{
    display: block;
    position: absolute;
    content: '';
    width: 98px;
    height: 89px;
    background: url(../images/gf_sub_ico.png)no-repeat center left;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
}

.gf_param{
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 100px;
}

.form_in{
    min-width: 430px;
}
.form_in > span{
    display: block;
    font-size: 20px;
    line-height: 1;
    color: rgba(255, 255, 255, .75);
}
.gfp_in{
    min-width: 430px;
}
.gfp_in > span{
    display: block;
    font-size: 20px;
    line-height: 1;
    color: rgba(255, 255, 255, .75);
}

.gf_nav{
    display: block;
    text-align: left;
    font-size: 0;
    box-sizing: border-box;
    margin-top: 23px;
}
.gf_nav a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: 16px;
    color: rgba(255, 255, 255, .75);
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    transition: .3s;
}
.gf_nav a:hover{
    color: #44e0f2;
}
.gf_nav a span{
    display: block;
    position: relative;
    padding: 0 7px;
    z-index: 2;
}
.gf_nav a:before{
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    opacity: 1;
    bottom: -2px;
    left: 7px;
    right: 7px;
    background: #fff;
    transition: .3s;
    z-index: 1;
}
.gf_nav a:hover:before{
    opacity: 0;
}
.gf_nav a.act:before{
    opacity: 1;
    left: 0;
    right: 0;
    background: #44e0f2;
    bottom: -3px;
    height: 8px;
}
.gf_nav a+a{
    margin-left: 0px;
}


.slid_style_react.MuiSlider-root {
    padding: 0;
    margin-top: 20px;
    height: 1px;
    color: #D3D3D3;
}
.gf_param .slid_style_react.MuiSlider-root{
    margin-top: 9px;
}

.gf_param .gfp_in:nth-child(2){
    margin-top: 12px;
}
.slid_style_react .MuiSlider-rail{
    height: 1px;
    background: rgba(255,255,255, .3);
}
.slid_style_react .MuiSlider-valueLabel{
    font-size: 18px;
    top: auto;
    bottom: -40px;
    left: auto;
}
.slid_style_react .MuiSlider-valueLabel > span{
    width: 32px;
    height: auto;
    line-height: 100%;
    transform: none;
    border-radius: 0px;
    background-color: initial;
}
.slid_style_react .MuiSlider-valueLabel > span > span{
    transform: none;
    color: #fff;
    font-family: EuropeCond, sans-serif;
}
.slid_style_react .MuiSlider-thumb.MuiSlider-active{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb.Mui-focusVisible, .slid_style_react .MuiSlider-thumb:hover{
    box-shadow: none;
}
.slid_style_react .MuiSlider-thumb{
    width: 18px;
    height: 18px;
    background: #44cddd;
    box-sizing: border-box;
    /* border: 2px solid #e2001a; */
    margin-top: -9px;
}
.slid_style_react .MuiSlider-track {
    height: 1px;
    color: #44cddd;
}






.react_select_style{
    /* margin-top: 16px; */
    width: 100%;
}
.react_select_style.MuiInput-underline:after{
    display: none;
}
.react_select_style.MuiInput-underline:before{
    border-bottom: 1px solid  #638d91;
}
.react_select_style.MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #638d91;    
}
.react_select_style .MuiNativeSelect-select{
background: none;
padding: 0;
padding-right: 24px;
height: 42px;
}
.react_select_style.MuiInputBase-root{
    font-family: 'FranklinGothic-Book';
    color: rgba(255, 255, 255,.75);
    font-size: 20px;
}
.react_select_style .MuiNativeSelect-select:not([multiple]) option, .react_select_style .MuiNativeSelect-select:not([multiple]) optgroup{
    color: #000;
}
.react_select_style .MuiNativeSelect-select:focus{
    background: none;
}
.react_select_style .MuiNativeSelect-icon path{
    display: none;
}
.react_select_style .MuiNativeSelect-icon{
    background: url(../images/select_ar.svg)no-repeat center center;
    background-size: 10px;
    opacity: 1;
    transform: scale(1,1);
    transition: .2s;
}
.react_select_style .MuiNativeSelect-select:focus ~ .MuiNativeSelect-icon{
    opacity: .7;
    transform: scale(-1,-1);
    transition: .2s;
}


.gf_param .react_input_style{
    margin-top: 76px;
}
.react_input_style  .MuiFormControl-root{
    width: 100%;
}
.react_input_style label + .MuiInput-formControl{
    padding: 0;
    margin-top: 0;
    font-size: 20px;
    color: rgba(255, 255, 255, .75);
    font-family: 'FranklinGothic-Book';
    transition: 0.3s;
}
.react_input_style .MuiInput-underline:before{
    border-bottom: 1px solid  #638d91;
}
.react_input_style .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #638d91;    
}
.react_input_style .MuiInput-underline:after{
    display: none;
}
.react_input_style .MuiInputBase-input{
    padding: 0;
    height: 54px;
    padding-left: 40px;
    box-sizing: border-box;
    color: rgba(255, 255, 255, .75);
}
.react_input_style .MuiInputLabel-root{
    padding: 0;
    left: 40px;
    font-size: 20px;
    color: rgba(255, 255, 255, .75);
    font-family: 'FranklinGothic-Book';    
    transform: translate(0, 18px) scale(1);
}
.react_input_style .MuiInputLabel-shrink{
    transform: translate(0, 0px) scale(.75);
}
.react_input_style .MuiFormLabel-root.Mui-focused{
    color: rgba(255, 255, 255, .45);
}
.gf_name .MuiInput-formControl{
    background: url(../images/gf_name_ico.svg)no-repeat center left;
    background-size: 24px;
}
.gf_mail .MuiInput-formControl{
    background: url(../images/gf_mail_ico.svg)no-repeat center left;
    background-size: 24px;
}
.gf_phone .MuiInput-formControl{
    background: url(../images/gf_phone_ico.svg)no-repeat center left;
    background-size: 24px;
}


.pp_time .MuiNativeSelect-select.MuiNativeSelect-select{
    background: url(../images/pp_time_ico.svg)no-repeat center left;
    background-size: 24px;
    padding-left: 40px;
}

.gf_btn{
    display: block;
    width: 100%;
    line-height: 55px;
    text-transform: uppercase;
    font-size: 16px;
    color: #168291;
    background: #e7cdb2;
    box-sizing: border-box;
    border: 1px solid #e7cdb2;
    margin-top: 52px;
    transition: 0.3s;
}
.gf_btn:hover{
    background: none;
    color: #fff;
}
/* get_flat */

/* galery */
.galery{
    display: block;
    position: relative;
}
.galery .ct_main{
    display: block;
    position: absolute;
    z-index: 10;
    top: 80px;
    left: 50%;
    width: 1430px;
    transform: translateX(-50%);
}
.galery .ct_main .title_main{
    color: #fff;
}
/* .gal_slid .slick-arrow{
    display: none !important;
} */
.gal_in{
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.gal_in img{
    display: block;
    width: 100%;
}
.gal_nav{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
    width: 430px;
    height: 120px;
    background: #168291;
    z-index: 11;
}
.gal_counter{
    display: block;
    cursor: default;
}
.gal_counter b{
    color: #fff;
    font-weight: normal;
    font-size: 26px;
    line-height: 1;
}
.gal_counter span{
    color: #fff;
    font-size: 16px;
    line-height: 1;
    padding-left: 4px;
}
.container_main.App .gal_slid .slick-next, .container_main.App .gal_slid .slick-prev{
    bottom: -10px;
}
.container_main.App .gal_slid .slick-prev{
    left: 46.5%;
}
.container_main.App .gal_slid .slick-next{
    right: 46.5%;
}


.container_main.App .gal_slid .slick-next{
    background: url(../images/gal_ar_next.png);
}
.container_main.App .gal_slid .slick-prev{
    background: url(../images/gal_ar_prev.png);
}


.container_main.App .gal_slid .slick-next:hover{
    background: url(../images/gal_ar_next.png);
    opacity: .5;
}
.container_main.App .gal_slid .slick-prev:hover{
    background: url(../images/gal_ar_prev.png);
    opacity: .5;
}


/* .gal_ar{
    display: inline-block;
    vertical-align: top;
} */


/* galery */

/* how_buy */
.how_buy{
    display: block;
    padding-top: 130px;
    padding-bottom: 130px;
}
.how_buy .ct_main{
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    align-content: center;
    justify-content: space-between;
}
.how_buy .title_main{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    width: 445px;
    min-width: 445px;
}
.hb_sub_tit{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.1;
    color: #168291;
    margin-top: 66px;
    padding-left: 100px;
}
.hb_sub_tit:before{
    display: block;
    position: absolute;
    content: '';
    width: 107px;
    height: 110px;
    background: url(../images/hb_sub_ico.png)no-repeat center left;
    top: 50%;
    transform: translateY(-50%);
    left: -12px;
}
.hb_variants {
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 177px;
    margin-bottom: -19px;
}
.hb_in {
    margin-left: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: 60px 12px 42px 40px;
    width: calc(50% - 16px);
    height: 234px;
    cursor: pointer;
    margin-bottom: 19px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.hb_in img{
    margin-top: 20px;
}
.hb_ipot{
    background: url(../images/hb_ipot.jpg)no-repeat center center;
    background-size: cover;
}
.hb_rassr{
    background: url(../images/hb_rassr.jpg)no-repeat center center;
    background-size: cover;
}
.hb_mc{
    background: url(../images/hb_mc.jpg)no-repeat center center;
    background-size: cover;
}
.hb_ipot img{
    width: 46px;
}
.hb_rassr img{
    width: 40px;
}
.hb_mc img{
    width: 40px;
}
.hb_in span{
    text-transform: uppercase;
    color: #fff;
    font-size: 20px;
    line-height: 1;
}
.hb_in a{
    display: inline-block;
    position: relative;
    margin-top: 30px;
    text-decoration: underline #1db4c8;
    font-size: 18px;
    color: #1db4c8;
    transition: 0.3s;
}
.hb_in:hover a{
    text-decoration: none;
}
.hb_in a:before{
    display: block;
    position: absolute;
    content: '?';
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    background: #20b1c2;
    top: 50%;
    right: -33px;
    transform: translateY(-50%);
}
/* how_buy */

/* important */
.important{
    display: block;
    position: relative;
    padding-top: 110px;
    padding-bottom: 70px;
    background: #f9fbfd;
}
.important .ct_main{
    position: relative;
    z-index: 2;
}
.imp_text{
    display: block;
    margin-top: 40px;
    font-size: 20px;
    line-height: 1.1;
    color: #1f2225;
    /* padding-left: 30px; */
    max-width: 690px;
    box-sizing: border-box;
}
.imp_list{
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 38px;
    /* padding-left: 30px; */
    max-width: 700px;
}
.imp_list a{
    display: inline-block;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: underline;
    color: #168291;
    cursor: pointer;
    transition: .3s;
}
.imp_list a+a{
    margin-top: 22px;
}
.imp_list a:hover{
    text-decoration: none;
    color: #c9b7a3;
}
.imp_list a.act{
    text-decoration: none;
    color: #c9b7a3;
}

.imp_right{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}
.imp_right>img{
    display: block;
    height: 100%;
}


.imp_icons{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.imp_icons img{
    display: block;
    width: 100%;
}
.imp_right .map_logo {
    display: block;
    position: absolute;
    width: 71px;
    height: 80px;
    left: 42%;
    top: 49%;
    z-index: 111;
    transform: translate(-50%,-50%);
    transition: .7s;
    animation: map_logo 5s infinite ease-in-out;
}
@keyframes map_logo{
    0%{transform: translate(-50%,-50%)  rotateY(0deg);}
    25%{transform: translate(-50%,-50%)  rotateY(180deg);}
    /* 35%{transform: translate(-50%,-50%)  rotateY(0deg);} */
    /* 50%{transform: translate(-50%,-50%)  rotateY(180deg);} */
    50%{transform: translate(-50%,-50%)  rotateY(0deg);}
    100%{transform: translate(-50%,-50%)  rotateY(0deg);}
}
/* important */

/* commercial_property */
.commercial_property{
    display: block;
    position: relative;
    background: #f9fbfd;
}
.cp_wrap{
    display: block;
    font-size: 0;
}
.cp_img, .cp_info{
    display: inline-block;
    vertical-align: top;
    width: 50%;
}
.cp_img{
    background: url(../images/cpi_bg.jpg)no-repeat center top;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    }
.cp_info {
    box-sizing: border-box;
    padding: 110px 20px 74px 82px;
}
.cp_info{
    float: right;
}
.cp_info .title_main{
    padding-left: 0;
}
.cp_info > p{
    display: block;
    max-width: 610px;
    box-sizing: border-box;
    padding-right: 10px;
    font-size: 24px;
    color: #1f2225;
    line-height: 1.1;
    margin-top: 40px;
}
.cpi_list{
    display: block;
    margin-top: 52px;
}
.cpi_list > span{
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    color: #168291;
    line-height: 1;
}
.cpi_list li span{
    display: block;
    position: relative;
    font-size: 20px;
    color: #1f2225;
    padding-left: 30px;
    box-sizing: border-box;
    text-transform: uppercase;
    margin-top: 26px;
}
.cpi_list li span:before {
    display: block;
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: url(../images/cpi_ico.svg)no-repeat center center;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
}
.cpi_btn{
    display: block;
    width: 290px;
    line-height: 54px;
    font-size: 16px;
    text-transform: uppercase;
    background: #168291;
    color: #fff;
    border: 1px solid #168291;
    /* font-weight: 500; */
    margin-top: 54px;
    transition: 0.3s;
}
.cpi_btn:hover{
    background: none;
    color: #168291;
}

/* commercial_property */

/* hod_str */
.hod_str{
    display: block;
    position: relative;
    padding-top: 140px;
    padding-bottom: 60px;
}
.hds_inn{
    display: block;
    width: 100%;
    padding: 0 2px;
    box-sizing: border-box;
}
.hds_inn img{
    display: block;
    width: 100%;
}

.container_main.App .hds_slid  .slick-next, .container_main.App .hds_slid  .slick-prev{
    top: auto;
    bottom: auto;
    top: -58px;
}

.online_hds{
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 230px;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    color: #fff;
    background: #168291;
    text-decoration: none;
    transition: 0.3s;
    margin-top: 0px;
    float: right;
    padding-left: 40px;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    box-sizing: border-box;
}
.online_hds:hover{
    text-decoration: none;
}
.online_hds:before{
    display: block;
    position: absolute;
    content: '';
    width: 99px;
    height: 88px;
    background: url(../images/hds_ico1.png)no-repeat center top;
    background-size: 100%;
    left: -55px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events:none;
}
.hds_slid{
    margin-top: 116px;
}
.hds_nav{
    display: block;
    margin-top: 40px;
    text-align: center;
    display: flex;
    align-items: center;
}
.hds_year {
    display: inline-block;
    display: flex;
    align-items: center;
    vertical-align: bottom;
}
.hds_year a{
    display: inline-block;
    position: relative;
    width: 70px;
    font-size: 16px;
    color: #1b1b1b;
    text-decoration: none;
    text-align: center;
    transition: .3s;
}
.hds_year a+a{
    margin-left: 6px;
}
.hds_year a span{
    position: relative;
    z-index: 2;
}
.hds_year a:hover{
    font-size: 30px;
}
.hds_year a:before{
    display: block;
    position: absolute;
    content: '';
    height: 1px;
    background: #1b1b1b;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1px;
    z-index: 1;
    transition: .3s;
}
.hds_year a:hover:before{
    width: 100%;
    height: 8px;
    background: #168291;
    bottom: 3px;
}
.hds_year a.act{
    font-size: 30px;
}
.hds_year a.act:before{
    width: 100%;
    height: 8px;
    background: #168291;
    bottom: 3px;
    transition: .3s;
}
.hds_month{
    display: inline-block;
    vertical-align: bottom;
    margin-left: 160px;
}
.hds_month a{
    display: inline-block;
    vertical-align: top;
    font-size: 26px;
    text-transform: uppercase;
    color: #1b1b1b;
    transition: .3s;
}
.hds_month a:hover{
    text-decoration: none;
    color: #d1b190;
}
.hds_month a.act{
    text-decoration: none;
    color: #168291;
}
.hds_month a+a{
    margin-left: 24px;
}
/* hod_str */

/* develop */
.develop{
    display: block;
    position: relative;
    padding-top: 40px;
    padding-bottom: 60px;
}
.develop .ct_main{
    position: relative;
    padding-left: 30px;
}
.develop .title_main{
    padding-left: 0;
    left: 0;
} 
.develop .title_main img{
    display: block;
    margin-top: 48px;
    max-width: 320px;
}
.dev_side{
    display: block;
    max-width: 600px;
    margin: 0 auto;
    margin-left: 0;
}
.dev_side > img{
    display: block;
    position: absolute;
    width: 50%;
    top: 0px;
    right: 0;
}
.develop .ct_main .dev_side > p{
    display: block;
    margin-top: 34px;
    font-size: 24px;
    color: #1f2225;
    line-height: 1.1;
}
.dev_side .MuiAccordion-root{
    box-shadow: none;
    margin-top: 26px;
}
.dev_side .MuiAccordion-root:before{
    display: none;
}
.dev_side .MuiAccordionSummary-root {
    display: flex;
    padding: 0px;
    min-height: 1px;
}
.dev_side .MuiAccordionSummary-content{
    margin: 0 auto;
}
.dev_side .MuiAccordionDetails-root .MuiTypography-root{
    font-family: 'FranklinGothic-Book';
    font-size: 18px;
    line-height: 1.1;
}
.dev_side .MuiAccordionDetails-root{
    padding: 0;
}

.dev_side .MuiAccordionDetails-root .MuiTypography-root p + p{
    margin-top: 12px;
}
.ct_text_btn {
    margin-top: 32px;
    color: #cacaca;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.1;
    transition: 0.3s;
}
.ct_text_btn:hover{
    color: #000;
}
.dev_list {
    display: flex;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 32px;
    padding-top: 38px;
    padding-bottom: 38px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
}
.dev_list span{
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 50px;
    box-sizing: border-box;
    color: #1b1b1b;
    font-size: 18px;
    text-transform: uppercase;
    min-width: 266px;
}
.dev_list span+span+span{
    margin-top: 31px;
}
.dev_list span img{
   display: block;
   position: absolute;
   width: 40px;
   max-height: 42px;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
}
.project_dec_btn{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 1;
    margin-top: 66px;
    padding-left: 108px;
    text-decoration: underline;
}
.project_dec_btn:hover{
    text-decoration: none;
}
.project_dec_btn:before {
    display: block;
    position: absolute;
    content: '';
    width: 94px;
    height: 88px;
    background: url(../images/pr_dec_btn.png)no-repeat center top;
    background-size: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

/* develop */
/* contacts */
.contacts{
    display: block;
    position: relative;
}

.social_media{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-direction: row;
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    /* height: 100%; */
    /* width: 68px; */
    width: auto;
    box-sizing: border-box;
    margin-top: 32px;
    /* padding-bottom: 50px; */
}
.social_media a{
    display: inline-block;
    vertical-align: top;
    position: relative;
    height: 34px;
    opacity: 1;
    /* margin: 0 auto; */
    margin-left: 0;
    transition: .3s;
    z-index: 2;
}
.social_media a img {
    display: block;
    height: 34px;
}
.social_media a:hover{
    opacity: 0.7;
}
.social_media a+a{
    margin-left: 12px;
}

/* .social_media:before {
    display: block;
    position: absolute;
    content: '';
    width: 2px;
    height: 1px;
    background: initial;
    box-shadow: 0px 0px 110px 100px rgb(0 0 0 / 10%);
    top: 50%;
    left: 0%;
    opacity: 1;
    border-radius: 50%;
    transform: translate(-50%, 0%) scale(1);
    pointer-events: none;
    z-index: 1;
    transition: .3s;
} */
.vk_soc{
    background: url(../images/vk_ico.svg)no-repeat center center;
}
.insta_soc{
    background: url(../images/insta_ico1.svg)no-repeat center center;
}
.dev_icon{
    background: url(../images/dev_icon.svg)no-repeat center center;
}
.face_soc{
    background: url(../images/face_ico.svg)no-repeat center center;
}
.odnokl_soc{
    background: url(../images/odnokl_ico.svg)no-repeat center center;
}
a.youtube_icon {
    background: url(../images/youtube_icon.png)no-repeat center center;
    background-size: 100%;
    width: 50px;
}
.cont_wrap{
    display: block;
    position: relative;
    font-size: 0;
}
.cont_info{
    display: block;
    position: relative;
    width: 44%;
    box-sizing: border-box;
    padding-left: 100px;
    padding-top: 90px;
    padding-bottom: 188px;
    background: #fcfdfe;
    float: right;
}
.cont_map{
    display: block;
    position: absolute;
    width: 56%;
    height: 100%;
    left: 0;
    top: 0;
}
.cont_info .title_main{
    padding: 0;
}
.cont_list{
    display: block;
    margin-top: 76px;
}
.ctl_in{
    display: block;
}
.ctl_in img{
    display: inline-block;
    vertical-align: middle;
    max-width: 30px;
}
.ctl_text{
    display: inline-block;
    vertical-align: middle;
    margin-left: 14px;
    font-size: 16px;
    line-height: 1.1;
    text-transform: uppercase;
}
.ctl_text span{
    display: block;
    color: #999999;
}
.ctl_text p{
    display: block;
}
.ctl_text b{
    display: block;
    color: #1b1b1b;
    font-size: 22px;
    font-weight: 500;
}
.ctl_text b a{
    color: #1b1b1b;
    text-decoration: none;
}
.ctl_in+.ctl_in{
    margin-top: 32px;
}

.cnt_btn{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    width: 490px;
    height: 114px;
    bottom: 0;
    left: 0;
    background: url(../images/cnt_btn_bg.jpg)no-repeat center center;
    background-size: cover;
}
.cnt_btn span{
    display: block;
    font-size: 28px;
    line-height: 1;
    color: #fff;
    text-transform: uppercase;
}
.cnt_btn a{
    color: #e7cdb2;
    font-size: 22px;
    line-height: 1.1;
    margin-top: 6px;
}
.cnt_btn a:hover{
    text-decoration: none;
}
/* contacts */

/* footer */
.footer{
    background: #2c3036;
    padding-top: 24px;
    padding-bottom: 24px;
}
.footer .ct_main{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 1.1;
    color: #fff;
}
.f_left{
    display: inline-block;
    vertical-align: middle;
    max-width: 200px;
}
.f_left img{
    display: block;
    max-width: 100%;
}
.f_left img+img{
    margin-top: 12px;
}
.f_left a{
    display: block;
    margin-top: 12px;
}
.f_left span {
    display: block;
    opacity: 0.6;
    margin-top: 6px;
}
.f_center{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.f_center span{
    display: block;
}
.f_center span + span {
    margin-top: 15px;
}
.f_center span a{
    display: inline-block;
    vertical-align: top;
    color: #168291;
    text-decoration: underline #168291;
    text-align: center;
}
.f_center span a:hover{
    text-decoration: none;
}
.f_right{
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}
.f_right span{
    display: block;
    opacity: 0.6;
}
.f_right img{
    margin-top: 10px;
}
/* footer */


/* presentation */
.presentation{
    display: block;
    background: url(../images/presentation_bg.jpg)no-repeat center top;
    background-size: cover;
    height: 100vh;
    box-sizing: border-box;
}
.presentation .ct_main{
    display: flex;
    align-items: flex-end;
    align-content: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 13vh;
}
.presentation .title_main{
    color: #fff;
    padding-left: 0;
}
.presentation .ly_sub_tit{
    color: #e7cdb2;
    font-size: 22px;
}
.top_logo_wrap{
    display: block;
    position: fixed;
    max-width: 1430px;
    width: 100%;
    margin: 0 auto;
    padding-top: 10vh;
    top: 0;
}
.top_logo_wrap img{
    display: block;
    margin: 0 auto;
    margin-left: 0;
    width: 150px;
}
.pp_inner{
    display: block;
    margin: 0 auto;
    width: 100%;
}
.present_form {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 110px;
}
.present_form .p_in{
    display: inline-block;
    vertical-align: top;
    width: 30%;
}
/* .present_form .p_in+.p_in{
    margin-left: 4%;
} */
.present_form .react_select_style .MuiNativeSelect-select{
    height: 54px;
}

.present_btn{
    display: block;
    width: 100%;
    line-height: 55px;
    text-transform: uppercase;
    font-size: 16px;
    color: #168291;
    background: #e7cdb2;
    box-sizing: border-box;
    border: 1px solid #e7cdb2;
    margin-top: 52px;
    transition: 0.3s;
}
.present_btn:hover{
    background: none;
    color: #fff;
}
.present_form  .p_in_btn{
    display: block;
    margin: 0 auto;
}
/* presentation */



/*popup_rgba*/

.popup_rgba {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999999;
    /* background: url("../img/popup_rgba.png"); */
    background: rgba(6, 31, 35, .7);
    overflow: hidden;
    -ms-overflow-y: scroll;
        overflow-y: scroll;
}

    .popup_cell {
        display: table-cell;
        vertical-align: middle;
    }
    .popup_table {
        position: absolute;
        display: table;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    .closeform {
    display: block;
    position: absolute;
    background: url(../images/closeform.png) no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
    width: 31px;
    height: 31px;
    top: 40px;
    right: 40px;
    z-index: 999999;
}
    .closeform:hover{opacity:  0.5;}











   .popup_main {
        position: relative;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 570px;
        margin-bottom: 70px;
        background: #FFF;
        box-sizing: border-box;
        padding: 64px;
        padding-top: 100px;
    }
.pu_thx{
    padding-top: 74px;
}
    .pu_tm {
        display: block;
        position: relative;
        color: #1f2225;
        font-size: 36px;
        line-height: 1;
        text-transform: uppercase;
        box-sizing: border-box;
    }
    .pu_tm span{
        display: inline-block;
        position: relative;
    }
    .pu_tm span:before {
        display: block;
        position: absolute;
        content: '';
        background: url(../images/text_dec.svg)no-repeat center center;
        width: 51px;
        height: 38px;
        left: -29px;
        top: -28px;
        z-index: 2;
    }
    .pu_form{
        display: block;
        position: relative;
        width: 100%;
        margin-top: 60px;
    }
    .pu_form .form_in + .form_in{
        margin-top: 30px;
    }
    .pu_form .form_in{
        width: 100%;
        min-width: 1px;
    }


    

    .sale_left_btn{
        display: block;
        position: fixed;
        top: 84%;
        left: -45px;
        width: 125px;
        height: 36px;
        /* background: #168291 url(../images/sale_btn_icon.png)no-repeat center left 7px; */
        background: #168291;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .25);
        box-sizing: border-box;
        font-size: 16px;
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        text-transform: uppercase;
        text-align: center;
        text-decoration: none;
        color: #fff;
        transform: rotate(-90deg);
        overflow: hidden;
        z-index: 1111;
    }

    .sale_left_btn:after {
        content: '';
        position: absolute;
        top: -50%;
        bottom: -50%;
        background: linear-gradient(90deg,rgba(255,255,255,0.1),rgba(255,255,255,0.6) 100%);
        width: 45px;
        transform: skewX(-22deg);
        left: -65%;
        animation-name: blick;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }

.pu_sale{
    padding: 0;
    max-width: 1140px;
}
.pu_sale  .btn_style button{
    background:#168291;
    color: #fff;
}
.pu_sale  .btn_style button:hover{
    background: none;
    color: #168291;
}
.ps_lside{
    display: block;
    width: 47%;
    float: right;
    padding: 100px 54px 30px 54px;
    box-sizing: border-box;
}
.ps_rside{
    display: block;
    float: left;
    width: 53%;
    font-size: 0;
}
.ps_rside img{
    display: block;
    width: 100%;
}
.ps_lside .pu_tm{
    text-align: center;
}
    
    .pu_form .react_input_style .MuiInputLabel-root{
        color: rgba(27,27,27, .4);
    }
    .pu_form .react_input_style .MuiFormLabel-root.Mui-focused{
        color: rgba(27,27,27, 1);
    }
    
    .pu_form .react_input_style .MuiInputBase-input{
        color: rgba(27,27,27, 1);
    }
    
    .pu_name .MuiInput-formControl{
        background: url(../images/pu_name.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_time .MuiInput-formControl{
        background: url(../images/pu_time.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_phone .MuiInput-formControl{
        background: url(../images/pu_phone.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_mail .MuiInput-formControl{
        background: url(../images/pu_mail.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_quest .MuiInput-formControl{
        background: url(../images/pu_quest.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_vznos {
        background: url(../images/pu_vznos.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_time_sel {
        background: url(../images/pu_time.svg)no-repeat center left;
        background-size: 24px;
    }
    .popup_main .react_select_style .MuiNativeSelect-select{
        padding-left: 40px;
        color: rgba(27,27,27, .4);
    }
    .popup_main .react_select_style .MuiNativeSelect-icon {
        background: url(../images/pu_sel_ar.svg)no-repeat center center;
        background-size: 18px;
        opacity: 1;
        transform: scale(1,1);
        transition: .2s;
    }

    
    .pu_name .MuiInput-formControl.Mui-focused{
        background: url(../images/pu_name_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_time .MuiInput-formControl.Mui-focused{
        background: url(../images/pu_time_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_phone .MuiInput-formControl.Mui-focused{
        background: url(../images/pu_phone_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_mail .MuiInput-formControl.Mui-focused{
        background: url(../images/pu_mail_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_quest .MuiInput-formControl.Mui-focused{
        background: url(../images/pu_quest_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_vznos.Mui-focused{
        background: url(../images/pu_vznos_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_time_sel.Mui-focused{
        background: url(../images/pu_time_act.svg)no-repeat center left;
        background-size: 24px;
    }


    
    .pu_name .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_name_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_time .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_time_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_phone .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_phone_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_mail .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_mail_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_quest .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_quest_act.svg)no-repeat center left;
        background-size: 24px;
    }
    .pu_vznos .MuiFormLabel-filled ~ .MuiInput-formControl{
        background: url(../images/pu_vznos_act.svg)no-repeat center left;
        background-size: 24px;
    }

.pu_zastr > img{
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 6px rgb(0 0 0 / 35%);
    background: #fff;
}
.pu_zastr.popup_main{
    padding-top: 90px;
}

.pu_zastr p+p{
    margin-top: 12px;
}.pu_zastr p{
    text-align: justify;
}
.pu_form .form_in.btn_style{
    margin-top: 52px;
}
.btn_style button{
    display: block;
    width: 100%;
    font-size: 16px;
    color: #168291;
    text-transform: uppercase;
    line-height: 55px;
    border: 1px solid #168291;
    box-sizing: border-box;
    transition: 0.3s;
    text-align: center;
}
.btn_style button:hover{
    background: #168291;
    color: #fff;
}





    
.thx_sub_tit{
    display: block;
    position: relative;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.1;
    color: #168291;
    margin-top: 46px;
    padding-left: 100px;
}
.thx_sub_tit:before{
    display: block;
    position: absolute;
    content: '';
    width: 98px;
    height: 89px;
    background: url(../images/thx_sub_ico.png)no-repeat center left;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
}


.react_input_style .MuiInputLabel-root{
    padding: 0;
    left: 40px;
    font-size: 20px;
    color: rgba(255, 255, 255, .75);
    font-family: 'FranklinGothic-Book';    
    transform: translate(0, 18px) scale(1);
}
.react_input_style .MuiInputLabel-shrink{
    transform: translate(0, 0px) scale(.75);
}
.react_input_style .MuiFormLabel-root.Mui-focused{
    color: rgba(255, 255, 255, .45);
}
.react_input_style.react_textarea .MuiInputBase-input{
    height: 54px!important;
    width: 100%!important;
    line-height: 54px;
}
.banks_list{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    flex-wrap: wrap ;
    margin-top: 28px;
}
.banks_list img{
    display: block;
    margin: 6px;
    max-height: 34px;
}
.pu_ipot .pu_form{
    margin-top: 24px;
}

.popup_main .react_input_style .MuiInput-underline:before{
    border-bottom: 1px solid  #ebebeb;
}

.popup_main .react_input_style .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 1px solid  #010d0e;    
}
.popup_main .MuiFormLabel-filled ~ .MuiInput-underline:before{    
    border-bottom: 1px solid  #010d0e;    
}

.pu_flat{
    display: block;
    max-width: 1200px;
    padding: 100px 80px 70px 100px;
}
.pu_flat .pu_title{
    display: inline-block;
    vertical-align: top;
}
.pu_area{
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    color: #168291;
    margin-top: 5px;
    line-height: 1;
}
.pu_area span{
    display: inline-block;
    vertical-align: top;
}
.pu_area span:before{
    display: none;
}
.pu_area span+span{
    margin-left: 28px;
}
.pu_area span sup{
    font-size: 12px;
    line-height: 1;
}
.pu_area span b{
    font-weight: 500;
}

.rooms_area{
    display: inline-block;
    position: relative;
    padding-left: 108px;
    box-sizing: border-box;
    font-size: 18px;
    font-style: italic;
    color: #9fabb7;
    line-height: 1.1;
    margin-top: 36px;
}
.rooms_area b{
    font-weight: 500;
    color: #1b1b1b;
}
.rooms_area b sup{
    font-size: 12px;
}
.rooms_area:before{
    display: block;
    position: absolute;
    content: '';
    background: url(../images/download_plan_ico.png)no-repeat  center center;
    background-size: 100%;
    width: 177px;
    height: 110px;
    left: -46px;
    transform: translateY(-50%);
    top: 64%;
    z-index: 2;
}
.flat_img{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 510px;
    height: 510px;
    float: right;
    margin-top: -58px;
}
.flat_img img{
    display: block;
    max-width: 100%;
    max-height: 510px;
}
.pu_flat .pu_form{
    display: inline-block;  
    max-width: 434px;
    margin-top: 54px;
}
.form_tit{
    display: block;
    text-transform: uppercase;
    font-size: 19px;
    color: #c4af98;
    line-height: 1.1;
    margin-bottom: 24px;
    font-weight: 500;
}

.pu_fin{
    display: block;
    max-width: 1200px;
    padding: 96px;
    padding-bottom: 80px;
}
.pu_fin_list{
    display: block;
    margin-top: 48px;
}
.pu_fin_list > span{
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    color: #168291;
    font-size: 22px;
    line-height: 1.1;
    text-align: left;
}
.pu_fin_list {
    font-size: 0;
    text-align: right;
}
.pu_fin_list li{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    text-align: left;
}
.pu_fin_list li > span{
    display: block;
    position: relative;
    font-size: 18px;
    color: #1b1b1b;
    padding-left: 36px;
    margin-top: 18px;
}
.pu_fin_list li span:before {
    display: block;
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: url(../images/cpi_ico.svg)no-repeat center center;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
}
.pu_fin .pu_form .form_in{
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
    width: 310px;
}
.pu_fin .pu_form .form_in+.form_in{
    margin-left: 30px;
}
.pu_fin .pu_form .btn_style{
    margin-left: 30px;
}
.pu_doc{
    max-width: 870px;
}
.pd_list{
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 0;
    margin-top: 40px;
}
.pd_list li{
    display: inline-block;
    vertical-align: top;
    width: 65%;
}
.pd_list li+li{
    width: 35%;
}
.pd_list a{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    position: relative;
    color: #38c0d3;
    font-size: 16px;
    box-sizing: border-box;
    padding-left: 40px;
    text-transform: uppercase;
    line-height: 1.1;
    min-height: 36px;
}
.pd_list a:before{
    display: block;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 28px;
    height: 36px;
    background: url(../images/doc_ico.svg)no-repeat center center;
    background-size: 100%;
}
.pd_list a:hover{
    text-decoration: none;
}
.pd_list a+a{
    margin-top: 14px;
}

/* popup_rgba */
.kachestvo {
    padding-bottom: 123px;
    margin-top: 60px;
    display: block;
}

.kachestvo__inner {
    max-width: 1430px;
    margin: 0 auto;
}

.kachestvo__head {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.title__dop {
    display: flex;
    font-size: 16px;
    line-height: 120%;
    color: #C4AF98;
    text-transform: uppercase;
    -webkit-align-items: center;
            align-items: center;
        margin-left: 50px;
}

    .title__dop img {
        display: block;
        margin-right: 24px;
    }

.kachestvo__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -20px;
    margin-top: 59px;
    margin-bottom: -41px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

    .kachestvo__list > li {
        width: 25%;
        padding-left: 20px;
        margin-bottom: 41px;
        -webkit-box-sizing: border-box;
           -moz-box-sizing: border-box;
                box-sizing: border-box;
    }

    .kachestvo__list > li i {
        display: flex;
        height: 59px;
        -webkit-align-content: center;
                align-content: center;
    }

        .kachestvo__list > li i img {
            display: block;
        }

    .kachestvo__list > li span {
        display: block;
        margin-top: 16px;
        font-size: 18px;
        line-height: 120%;
        color: #1B1B1B;
        max-width: 318px;
    }

.kachestvo .title_main span {
    text-align: left;
}

.apartments__form {
    display: block;
    margin-top: 51px;
    /* padding-left: 30px; */
}

.apartments__form_title {
    font-size: 19px;
    line-height: 120%;
    text-transform: uppercase;
    color: #C4AF98;
}

.apartments__form_inner {
    display: flex;
    margin-top: 32px;
    margin-left: -20px;
}

.apartments__form_inner .react_input_style .MuiInputBase-input, .apartments__form_inner .react_input_style .MuiInputLabel-root {
    color: rgba(27,27,27,0.4);
}

.apartments__form_inner .MuiInput-underline:before {
    border-color: rgba(27,27,27,0.4);
}

.apartments__form_inner .react_input_style .MuiInputBase-input {
    color: #1B1B1B;
}

.apartments__form_item {
    width: 33.333333%;
    padding-left: 20px;
    padding-top: 3px;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

    .apartments__form_item:last-child {
        padding-top: 0;
    }

.fin_apartments__inner {
    display: flex;
    position: relative;
}

.fin_apartments_l {
    min-width: 50%;
    width: 50%;
    padding-bottom: 63px;
}

.fin_apartments_r {
    position: absolute;
    left: 54%;
    height: 100%;
    width: calc(50% + (100vw / 2 - 54%));
    background: url(../images/fin_white_box1_2.jpg) no-repeat center center;
    -webkit-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
}

.apartments__form_inner .gfp_in, .apartments__form_inner .form_in {
    min-width: 20px;
}

.plan_price__head {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.plan_price__download {
    display: flex;
    margin-left: 71px;
    font-size: 18px;
    line-height: 120%;
    color: #168291;
    text-decoration: underline;
    -webkit-align-items: center;
            align-items: center;
}

    .plan_price__download:hover {
        text-decoration: none;
    }

    .plan_price__download i {
        position: relative;
        display: block;
        margin-right: 14px;
    }

    .plan_price__download i img {
        display: block;
        position: relative;
        z-index: 20;
    }

        .plan_price__download i:before {
            content: "";
            display: block;
            position: absolute;
            width: 177px;
            height: 88px;
            left: -48px;
            top: 15px;
            background: url("../images/download_plan_ico__shadow.png") no-repeat left top;
            opacity: 0.8;
        }

.ws_fixed {
    position: fixed;
    z-index: 100;
    right: 112px;
    bottom: 128px;
    -webkit-animation: anim_ws 10s ease-out infinite;
       -moz-animation: anim_ws 10s ease-out infinite;
         -o-animation: anim_ws 10s ease-out infinite;
            animation: anim_ws 10s ease-out infinite;
}

    .ws_fixed img {
        display: block;
        max-width: 70px;
    }


@keyframes anim_ws {
    40% {
        opacity: 1;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
    42% {
        opacity: 0.8;
        -webkit-transform: scale(1.2);
           -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
             -o-transform: scale(1.2);
                transform: scale(1.2);
    }
    44% {
        opacity: 1;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
    80% {
        -webkit-transform: scale(1) rotate(0);
           -moz-transform: scale(1) rotate(0);
            -ms-transform: scale(1) rotate(0);
             -o-transform: scale(1) rotate(0);
                transform: scale(1) rotate(0);
    }

    85% {
        -webkit-transform: scale(-1,1);
           -moz-transform: scale(-1,1);
            -ms-transform: scale(-1,1);
             -o-transform: scale(-1,1);
                transform: scale(-1,1);
    }

    90% {
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }
}

.pu_camera{
    max-width: 870px;
}
.pu_camera .pd_list a:before{
    display: none;
}
.pu_camera .pd_list a{
    padding-left: 0;
}

.pu_action {
    max-width: 930px !important;
}

.pu_action_list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -32px;
    margin-top: 34px;
}

    .pu_action_list > li {
        width: 33.3333%;
        padding-left: 32px;
        box-sizing: border-box;
    }

    .pu_al__item {
        position: relative;
        cursor: pointer;
    }

        .pu_al__item:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0,0,0,0.33);
            transition: 0.2s;
        }

        .pu_al__item:hover:before {
            background: rgba(0,0,0,0.27);
        }

        .pu_al__item img {
            display: block;
            width: 100%;
        }

.pu_al__info {
    position: absolute;
    z-index: 20;
    left: 18px;
    top: 15px;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
}

.pu_al__name {
    position: absolute;
    z-index: 30;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    line-height: 23px;
    flex-direction: column;
    color: #fff;
}

.pu_al__podrobno {
    position: absolute;
    z-index: 20;
    left: 18px;
    bottom: 19px;
    text-decoration: underline;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
}


.pu_al__item:hover .pu_al__podrobno {
    text-decoration: none;
}


.sub_podmena {
    font-size: 36px;
    line-height: 110%;
    padding-top: 10px;
    color: #e7cdb2;
    text-shadow: 1px 1px 0px #000;
}

/* countdown start */
/* fonts */
@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat-Black.eot');
	src: local('☺'), url('fonts/Montserrat-Black.woff') format('woff'), url('fonts/Montserrat-Black.ttf') format('truetype'), url('fonts/Montserrat-Black.svg') format('svg');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat-Regular.eot');
	src: local('☺'), url('fonts/Montserrat-Regular.woff') format('woff'), url('fonts/Montserrat-Regular.ttf') format('truetype'), url('fonts/Montserrat-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('fonts/Montserrat-SemiBold-600.eot');
	src: local('☺'), url('fonts/Montserrat-SemiBold-600.woff') format('woff'), url('fonts/Montserrat-SemiBold-600.ttf') format('truetype'), url('fonts/Montserrat-SemiBold-600.svg') format('svg');
	font-weight: 600;
	font-style: normal;
}


.countdown {
    font-family: 'Montserrat';
    position: fixed;
    z-index: 200;
    right: 20px;
    top: 100px;
    background: #000;
    border-radius: 10px;
    width: 350px;
    padding: 12px 12px 15px 12px;
    color: #fff;
}

.countdown__title {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 900;
}
.countdown__num {
    text-align: center;
    margin-top: 8px;
    font-size: 36px;
    font-weight: 900;
    line-height: 100%;
    letter-spacing: 3px;
}

.countdown__lab {
    text-align: center;
    margin-top: 2px;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    padding-left: 5px;
}

    .countdown__lab div {
        text-align: center;
        box-sizing: border-box;
        width: 56px;
    }

    .countdown__lab div {
        padding-right: 5px;
    }

    .countdown__lab div + div {
        margin-left: 8px;
        padding-right: 4px;
    }

    .countdown__lab div + div + div {
        padding-right: 0px;
    }

    .countdown__btn {
        text-align: center;
        margin-top: 8px;
    }

        .countdown__btn span {
            cursor: pointer;
            display: inline-block;
            user-select: none;
            font-size: 14px;
            height: 30px;
            line-height: 30px;
            color: #000;
            background: #fff;
            padding-left: 18px;
            padding-right: 18px;
            border-radius: 5px;
            transition: 0.2s;
        }

        .countdown__btn span:hover {
            opacity: 0.8;
        }

        .head_plashka_top {
            display: block;
            margin-top: 30px;
            width: 260px;
        }
.sub_tt {
    text-transform: uppercase;
}

.ms_inner sub i {
    font-style: normal;
    text-decoration: underline;
    color: #fff;
}

.ms_other {
    margin-top: 40px;
}
.ms_other_title {
    font-size: 26px;
    color: #fff;
}
.ms_other_href {
    display: flex;
    margin-top: 28px;
}

.ms_other_href a {
    display: block;
    text-decoration: none;
}

.ms_other_href a + a {
    margin-left: 60px;
}

.ms_other_href a img {
    display: block;
    max-width: 100%;
}

@media screen and (max-width: 640px) {
    .countdown__title {
        font-size: 12px;
    }
    .countdown__num {
        font-size: 26px;
    }
    .countdown__lab {
        font-size: 12px;
    }

    .countdown__lab div {
        width: 38px;
    }

    .countdown {
        top: 90px;
        width: 199px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media screen and (max-width: 540px) {
    .countdown {
        top: 62px;
    }
}

@media screen and (max-width: 420px) {
    .countdown__title {
        font-size: 7px;
        font-weight: 600;
    }
    .countdown {
        right: 10px;
        width: 135px;
        width: 95px;
        padding-bottom: 7px;
        padding-left: 2px;
        padding-right: 2px;
    }

    .countdown__num {
        font-size: 12px;
        font-weight: 600;
        margin-top: 2px;
    }

    .countdown__lab {
        font-size: 6px;
    }

    .countdown__lab div {
        width: 20px;
        padding: 0 !important;
        position: relative;
    }

    .countdown__lab div + div {
        margin-left: 10px;
        left: -4px;
    }

    .countdown__lab div + div + div {
        left: -6px;
    }

    .countdown__btn {
        margin-top: 5px;
    }

    .countdown__btn span {
        font-size: 8px;
        padding-left: 10px;
        padding-right: 10px;
        height: 20px;
        line-height: 20px;
    }
}
/* countdown end */

.popup_action {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    background: url("../images/popup_action_bg.jpg") no-repeat right bottom;
    background-size: cover;
    box-sizing: border-box;
    padding: 65px 30px 126px 73px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.popup_action .closeform {
    background-color: rgba(255,255,255,1);
    padding: 5px;
    transition: 0.3s;
    border-radius: 50px;
    background-size: 25px;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.7);
}

.popup_action_title {
    font-size: 64px;
    line-height: 73px;
    color: #0A5F6B;
    text-shadow: 2px 2px 0px #FFFDFD;
}

.popup_action_inner {
    position: relative;
    z-index: 50;
}

.popup_action_img {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 500px;
}

.popup_action_form {
    max-width: 280px;
    margin-top: 99px;
}

.popup_action_form .react_input_style .MuiInputLabel-root {
    color: #000;
    z-index: 50;
    pointer-events: none;
}

.popup_action_form .form_in  {
    background: #fff;
    border-radius: 10px;
}

.popup_action_form .react_input_style .MuiInputLabel-shrink {
    top: -11px;
    background: #fff;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    left: 33px;
    border-radius: 5px;
}


.popup_action_form .form_in {
    margin-bottom: 17px;
    min-width: 20px;
    background: #fff;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
}

.popup_action_form .form_in  input {
    color: #000;
    padding-top: 10px;
    box-sizing: border-box;
}

.pu_action_list2 {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: -30px;
    margin-top: 30px;
}

.pu_action_list2 > li {
    width: 50%;
    padding-left: 30px;
    box-sizing: border-box;
}

.pal__img img {
    display: block;
    width: 100%;
}

.pal__item {
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px #BCA17E;
    border-radius: 5px;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    transition: 0.3s;
    position: relative;
    top: 0px;
}

    .pal__item:hover {
        top: -5px;
    }

.pal__name {
    text-align: center;
    font-size: 24px;
    line-height: 120%;
    text-transform: uppercase;
}

.pal__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    height: 350px;
}

.pal__img img {
    display: block;
    max-width: 100%;
    max-height: 350px;
}

.pal__info {
    font-size: 20px;
    color: #CC5C5E;
    font-style: italic;
    text-align: center;
}

.pal__area {
    font-size: 22px;
    font-weight: bold;
    margin-top: 25px;
    color: #002024;
    text-align: center;
}

.pal__item .btn_style {
    margin-top: 25px;
}