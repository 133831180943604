
/*@media*/
@media screen and (max-width:  960px) {
    .plan_price__download span {
        display: block;
        max-width: 200px;
    }
    .plan_price__download {
        margin-left: 48px;
    }
}
@media screen and (max-width:  920px) {
    .kachestvo__list > li {
        width: 33.333333%;
    }
    .kachestvo__head {
        display: block;
    }

    .title__dop {
        width: 100%;
        margin-top: 25px;
        margin-left: 0;
        -webkit-justify-content: center;
                justify-content: center;
    }
}
@media screen and (max-height:  880px){
    .popup_main{
        padding-top: 78px;
        padding-bottom: 40px;
    }
    .banks_list{
        margin-top: 12px;
    }
    .banks_list img{
        margin: 3px;
    }
    .pu_ipot .pu_form{
        margin-top: 12px;
    }
    .pu_form .form_in + .form_in {
        margin-top: 12px;
    }
    .pu_form .form_in.btn_style {
        margin-top: 16px;
    }
    .pu_sale{
        padding: 0;
        max-width: 964px;
    }
}
@media screen and (max-width:  860px) {
    .plan_price__head {
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .plan_price__download {
        margin-left: 0;
        margin-top: 20px;
    }
}
@media screen and (max-height:  780px){
    .main_screen{
        max-height: 720px;
    }
    
    .present_form{
        margin-top: 50px;
    }
    .present_btn{
        margin-top: 30px;
    }
}
@media screen and (max-height:  740px) {
    .pu_action_list {
        margin-left: -20px;
    }

    .pu_al__name {
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
    }
        .pu_al__name br {
            display: none;
        }

    .pu_action_list > li {
        padding-left: 20px;
    }
}
@media screen and (max-height:  675px){
    .main_screen{
        max-height: 670px;
    }
}
@media screen and (max-height:  655px){
    .main_screen{
        max-height: 640px;
    }
    .top_logo_wrap{
        padding-top: 6vh;
    }
    .top_logo_wrap img{
        width: 80px;
    }
    .presentation .ct_main {
        padding-bottom: 10vh;
    }
}
@media screen and (max-height:  556px){
    .main_screen{
        max-height: 554px;
    }
}
@media screen and (max-height:  750px){
    logo:before{
        box-shadow: 0px 0px 110px 170px rgb(255 255 255 / 80%);
    }
    .pd_list a {
        font-size: 12px;
        padding-left: 26px;
        min-height: 26px;
    }
    .pd_list a:before {
        width: 20px;
        height: 26px;
    }
    .pd_list a+a {
        margin-top: 8px;
    }
    .pu_doc {
        max-width: 600px;
    }
    .pu_camera {
        max-width: 600px;
    }
    .urban_ico {
        width: 78px;
        top: -120px;
    }

    .head_plashka {
        left: 106px;
        top: -103px;
        width: 130px;
    }

}
@media screen and (max-height:  680px){
    .wb_btn {
        top: -60px;
        width: 520px;
    }
    logo{
        padding-top: 18px;
    }
    .msl_el{
        padding-bottom:22px;
    }

    .next_bl_ar {
        bottom: 6px;
        width: 20px;
        height: 11px;
    }
    .project_financing {
        width: 240px;
        height: 66px;
    }
    .apart_info_box{
        width: 150px;
        height: 150px;
    }
    sub{
        font-size: 20px;
    }
    h1{
        font-size: 72px;
    }
    /* .social_media{
        width: 30px;
    }
    .social_media:before {
        display: block;
        position: absolute;
        content: '';
        width: 2px;
        height: 1px;
        background: initial;
        box-shadow: 0px 0px 110px 200px rgb(0 0 0 / 10%);
        top: 50%;
        left: 0%;
        opacity: 1;
        border-radius: 50%;
        transform: translate(-50%, 0%) scale(1);
        z-index: 1;
        transition: .3s;
    }
    .social_media a{
        position: relative;
        z-index: 2;
    } */












    .advantages_house{
        padding-top: 80px;
    }
    .ah_list{
        margin-top: 20px;
    }
    .ah_card{
        height: 320px;
    }
    .ah_card span{
        margin-top: 10px;
    }
    .large_yard{
        padding-top: 80px;
    }
    .loc {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .loc_in{
        margin-top: 26px;
    }
    .loc_in p{
        padding: 30px;
    }
    .transport{
        padding-top: 70px;
    }
    .infra{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .infra_text{
        margin-top: 12px;
    }
    .infra_list{
        margin-top: 40px;
    }
    .infra_in+.infra_in+.infra_in+.infra_in {
        margin-top: 18px;
    }
    .plan_price {
        padding-top: 70px;
        padding-bottom: 72px;
    }
    .plan_nav{
        margin-top: 30px;
    }
    .plan_slid{
        margin-top: 18px;
    }
    .get_flat{
        padding-top: 90px;
        padding-bottom: 40px;
    }
    .gf_sub_tit{
        margin-top: 36px;
    }
    .gf_param{
        margin-top: 30px;
    }
    .gf_param .react_input_style{
        margin-top: 44px;
    }
    .gf_btn{
        margin-top: 36px;
    }
    .how_buy{
        padding: 70px 0px;
    }
    .cp_info {
        padding: 60px 20px 48px 64px;
    }
    .cp_info > p{
        margin-top: 24px;
    }
    .cpi_list{
        margin-top: 26px;
    }
    .cpi_list li+li span{
        margin-top: 14px;
    }
    .cpi_btn{
        margin-top: 28px;
    }
    .hod_str {
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .hds_slid{
        margin-top: 76px;
    }
    .container_main.App .hds_slid .slick-next, .container_main.App .hds_slid .slick-prev{
        top: -32px;
    }
    .cont_info {
        padding-left: 70px;
        padding-top: 60px;
        padding-bottom: 140px;
    }
    .cont_list{
        margin-top: 26px;
    }
    .ctl_in+.ctl_in {
        margin-top: 14px;
    }
    .cnt_btn{
        height: 94px;
    }
    .footer{
        padding: 20px 0px;
    }
}
@media screen and (max-height:  650px){
    .ps_lside {
        padding: 80px 20px 26px 20px;
    }
    .pu_sale{
        max-width: 764px;
    }
    .ps_lside .pu_tm{
        font-size: 26px;
    }
    .pu_sale .pu_form{
        margin-top: 14px;
    }
    .ms_inner{
        padding-bottom: 100px;
    }
}
@media screen and (max-width: 1850px){
    .important {
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .imp_text {
        max-width: 490px;
    }
}
@media screen and (max-width: 1550px){
    .so_slid p{
        font-size: 20px;
    }
    .special_offer{
        padding-top: 50px;
    }
    .special_offer .title_main p {
        font-size: 20px;
        max-width: 240px;
        margin: 0 auto;
        margin-left: 0;
        margin-top: 18px;
        left: auto;
    }
    .so_img {
        max-width: 60%;
        top: 70px;
    }
    .so_in {
        padding-top: 70px;
        padding-bottom: 150px;
    }
    .imp_list{
        margin-top: 20px;
    }
    .imp_list a+a {
        margin-top: 12px;
    }
    .imp_text {
        margin-top: 18px;
        font-size: 18px;
    }
    .imp_text{
        padding-left: 20px;
    }
    .imp_list{
        padding-left: 20px;
    }
    .fin_apartments {
        margin-top: 80px;
    }
    .social_media{
        margin-top: 14px;
    }
    .mp_loc span{
        font-size: 15px;
    }
    .mp_number a{
        margin-right: 12px;
        font-size: 18px;
    }
    .fin_sub_list{
        margin-top: 30px;
    }
    .fin_sub_list li span {
        font-size: 16px;
        padding-left: 34px;
    }
    .fin_sub_list:before {
        width: 90px;
        height: 92px;
        background-size: 100%;
        bottom: 0px;
        right: 4px;
    }
    .wb_btn {
        top: -54px;
        width: 450px;
    }
    logo:before{
        top: 0;
    }
    .ct_main, .kachestvo__inner, .top_logo_wrap {
        max-width: 1130px;
    }
    .kachestvo {
        padding: 0 10px;
    }
    logo img{
        width: 110px;
    }
    .nav_menu {
        /* margin-left: 70px; */
    }
    .nav_menu a + a {
        margin-left: 8px;
    }
    .menu.menu_scrool .nav_menu{
        margin-left: 30px;
    }
    .mp_wrap {
        padding-top: 26px;
        padding-bottom: 12px;
    }
    .mp_loc{
        padding-top: 8px;
    }
    .ms_inner{
        /* padding-top: 50px; */
    }
    .msl_el {
        padding-bottom: 22px;
    }
    .project_financing {
        width: 240px;
        height: 66px;
    }
    .next_bl_ar {
        bottom: 6px;
        width: 20px;
        height: 11px;
    }
    .advantages_house{
        padding-top: 80px;
    }
    .ah_list{
        margin-top: 20px;
    }
    .ah_card{
        height: 320px;
    }
    .ah_card span{
        margin-top: 10px;
    }
    .large_yard{
        padding-top: 80px;
    }
    .loc {
        padding-top: 96px;
        padding-bottom: 110px;
    }
    .loc_in{
        margin-top: 26px;
        width: 49%;
    }
    .loc_in p {
        padding: 30px 12px;
        font-size: 16px;
    }
    .loc_in p span{
        font-size: 18px;
        padding-bottom: 14px;
    }
    .ly_title_info span{
        font-size: 16px;
    }
    .no_dark_places span {
        width: 336px;
        font-size: 18px;
        line-height: 64px;
    }
    .no_dark_places span:before {
        width: 180px;
        height: 180px;
        background-size: 100%;
        left: -96px;
    }
    .title_main{
        font-size: 33px;
        padding-left: 20px;
        left: auto;
    }
    .excurs .ct_main:before {
        background-size: 100%;
        width: 425px;
        height: 258px;
    }
    .tl_in{
        min-width: 100px;
    }
    .tl_in_main:before {
        width: 228px;
        height: 74px;
        z-index: 1;
    }
    .tl_in_main span{
        font-size: 16px;
    }
    .tl_in span{
        font-size: 14px;
    }
    .tl_in b {
        font-size: 14px;
        line-height: 1.3;
    }
    .infra{
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .infra_text{
        margin-top: 12px;
        font-size: 14px;
    }
    .infra_list{
        margin-top: 40px;
    }
    .infra_in+.infra_in+.infra_in+.infra_in {
        margin-top: 18px;
    }
    .infra_in span{
        font-size: 16px;
    }
    .plan_price {
        padding-top: 70px;
        padding-bottom: 72px;
    }
    .plan_nav{
        margin-top: 30px;
    }
    .plan_slid{
        margin-top: 18px;
    }
    .ps_inn{
        width: 270px!important;
        padding: 14px;
    }
    .room_num {
        margin-top: 14px;
        font-size: 16px;
    }
    .fin_sub_tit{
        margin-top: 36px;
        font-size: 14px;
    }
    .fin_in {
        width: 48%;
        height: 186px;
        padding: 24px;
    }
    .fin_variants{
        width: 58%;
    }
    .get_flat{
        padding-top: 90px;
        padding-bottom: 40px;
    }
    .gf_sub_tit{
        margin-top: 36px;
    }
    .gf_param{
        margin-top: 30px;
        justify-content: space-between;
    }
    .gf_param .react_input_style{
        margin-top: 44px;
    }
    .gf_btn{
        margin-top: 36px;
    }
    .gf_param .gfp_in:last-child{
        margin: 0 auto;
    }
    .gf_param .gfp_in{
        min-width: 30%;
    }
    .gf_nav{
        margin-top: 25px;
    }
    .gf_nav a{
        font-size: 13px;
    }
    .gf_nav a span{
        padding: 0 4px;
    }
    .gf_nav a+a {
        margin-left: 3px;
    }
    .gf_nav a:before {
        left: 4px;
        right: 4px;
    }
    .how_buy{
        padding: 70px 0px;
    }
    .how_buy .title_main {
        width: 375px;
        min-width: 375px;
    }
    .hb_variants {
        margin-left: 57px;
    }
    .hb_in span {
        font-size: 14px;
    }
    .hb_sub_tit{
        margin-top: 56px;
    }
    .cp_info {
        padding: 60px 20px 48px 64px;
    }
    .cp_info > p{
        margin-top: 24px;
    }
    .cpi_list{
        margin-top: 26px;
    }
    .cpi_list li+li span{
        margin-top: 14px;
    }
    .cpi_btn{
        margin-top: 28px;
    }
    .cp_info > p{
        font-size: 18px;
    }
    .hod_str {
        padding-top: 70px;
        padding-bottom: 40px;
    }
    .hds_slid{
        margin-top: 76px;
    }
    .container_main.App .hds_slid .slick-next, .container_main.App .hds_slid .slick-prev{
        top: -32px;
    }

    .develop .ct_main .dev_side > p{
        margin-top: 24px;
        font-size: 16px;
    }
    .dev_side .MuiAccordionDetails-root .MuiTypography-root{
        font-size: 14px;
    }
    .dev_side .MuiAccordion-root{
        margin-top: 12px;
    }
    .dev_side {
        max-width: 46%;
    }
    .dev_list{
        padding-top: 18px;
        padding-bottom: 18px;
        margin-top: 12px;
    }
    .dev_list span {
        font-size: 15px;
        width: 47%;
        min-width: 1px;
    }
    .dev_list span+span+span {
        margin-top: 23px;
    }
    .ct_text_btn {
        margin-top: 12px;
        font-size: 16px;
    }
    .cont_info {
        padding-left: 70px;
        padding-top: 60px;
        padding-bottom: 140px;
    }
    .cont_list{
        margin-top: 26px;
    }
    .ctl_in+.ctl_in {
        margin-top: 14px;
    }
    .cnt_btn{
        height: 94px;
    }
    .footer{
        padding: 20px 0px;
    }
}
@media screen and (max-width: 1360px) {
    .imp_text {
        font-size: 15px;
        max-width: 420px;
    }
    .fin_apartments_r {
        left: 60%;
        width: calc(50% + (100vw / 2 - 60%));
    }

    .fin_apartments_l {
        width: 58%;
        min-width: 20px;
    }
}
@media screen and (max-width: 1250px){
    .ms_inner.ct_main {
        padding-left: 50px;
    }
    .menu .ct_main{
        padding: 0  10px;
    }
    .footer .ct_main{
        padding: 0 10px;
    }
    .ms_inner{
        padding-bottom: 200px;
    }
}
@media screen and (max-width: 1150px){
    .head_plashka {
        left: 150px;
    }
    .special_offer .form_title {
        padding-bottom: 10px;
    }
    .special_offer .form_title br {
        display: none;
    }
    .hds_nav {
        display: block;
    }
    .hds_year a {
        font-size: 26px !important;
    }
    .hds_year a:hover {
        font-size: 26px;
    }
    .pu_zastr.popup_main{
        padding-top: 42px;
    }
    .hds_year > li + li {
        margin-top: 10px;
    }
    .hds_month {
        margin-top: 22px;
    }
    .so_slid{
        margin-top: 20px;
    }
    .so_slid p {
        font-size: 14px;
        max-width: 200px;
        margin: 0 auto;
        text-align: center;
    } 
    .so_in{
        padding-top: 0px;
        padding-bottom: 30px;
    }
    .special_offer .title_main p{
        margin: 0 auto;
        margin-top: 18px;
    }
    .cbk-phone.callbackkiller-mobile, .cbk-phone.callbackkiller-mobile:hover {
        bottom: 30px!important;
        right: 44px!important;
    }
    .ws_fixed{
        bottom: 78px;
        right: 22px;
    }
    .imp_right>img{
        height: auto;
    }
    .so_img{
        position: relative;
        top: auto;
        margin: 0 auto;
        margin-top: 10px;
        width: auto;
        max-width: 550px;
    }
    .special_offer .pu_form{
        margin: 0 auto;
        max-width: 550px;
        box-sizing: border-box;
        padding: 0 12px;
        text-align: center;
        margin-top: 40px;
    }
    .presentation{
        height: auto;
        min-height: 100vh;
    }
    .pp_inner{
        padding-top: 54px;
    }
    .top_logo_wrap{
        position: relative;
        display: block;
        padding-top: 30px;
    }
    .present_form {
        flex-direction: column;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .present_form .p_in{
        display: block;
        width: 100%;
    }
    .presentation .ct_main {
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 80px;
    }
    .present_form .p_in+.p_in{
        margin-top: 8px;
    }
    .present_btn{
        margin-top: 0;
    }

    
    .imp_text {
        font-size: 14px;
        max-width: 280px;
        margin: 0 auto;
        text-align: justify;
        margin-top: 20px;
        padding: 0;
    }
    .imp_list {
        max-width: 280px;
        padding: 0;
        margin: 0 auto;
        margin-top: 20px;
    }
    .imp_right {
        position: relative;
        height: auto;
        margin: 0 auto;
        max-width: 320px;
        margin-top: 20px;
    }
    .imp_right>img{
        max-width: 100%;
    }
    .imp_right .map_logo {
        width: 31px;
        height: 36px;
    }
    .hb_variants {
        margin-left: -16px;
    }
    .main_screen{
        background: url(../images/main_screen_bg_mob.jpg)no-repeat top center;
        background-size: cover;
        overflow: hidden;
        padding-top: 50px;
        padding-bottom: 110px;
    }
    .urban_ico{
        left: auto;
    }
    .bus_stop {
        width: 300px;
        padding-left: 121px;
        box-sizing: border-box;
    }
    .bus_stop:before {
        left: -32px;
    }
    .galery{
        padding-top: 80px;
    }
    .galery .ct_main {
        display: block;
        position: relative;
        z-index: 10;
        top: auto;
        left: auto;
        width: auto;
        transform: none;
    }
    .galery .ct_main .title_main{
        color: #1f2225;
    }
    .gal_slid {
        margin-top: 40px;
    }
    .container_main.App .gal_slid .slick-next {
        right: 38.5%;
    }
    .container_main.App .gal_slid .slick-prev {
        left: 38.5%;
    }
    .develop .title_main img{
        max-width: 100%;
    }
    .fs_btn{
        margin-left: 0;
        width: 100%;
    }
    .pd_list a {
        font-size: 12px;
        padding-left: 26px;
        min-height: 26px;
    }
    .pd_list a:before {
        width: 20px;
        height: 26px;
    }
    .pd_list a+a {
        margin-top: 8px;
    }
    .pu_doc {
        max-width: 600px;
    }
    .pu_camera {
        max-width: 600px;
    }
    .fin_sub_list:before{
        display: none;
    }
    .apartments__form_inner {
        display: block;
        margin-left: 0;
    }
    .apartments__form_item {
        width: auto;
        padding-left: 0;
        padding-top: 0;
    }

        .apartments__form_item + .apartments__form_item {
            margin-top: 20px;
        }

    .wb_btn {
        top: 0;
        width: 300px;
        margin: 0 auto;
        margin-top: 20px;
        position: relative;
    }
    .fin_sub_list li span {
        font-size: 15px;
    }
    .move .car_dec {
        left: calc(50% - 135px);
    }
    .car_dec {
        left: calc(50% - 135px);
    }
    .menu .ct_main{padding: 0;}
    .menu .nav_menu {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(22, 130, 145, .9);
        z-index: -1;
        pointer-events: none;
        opacity: 0;
        transition: .3s;
    }
    .menu .nav_menu.opened{
        z-index: 11;
        pointer-events:all;
        opacity: 1;
        transition: .3s;
    }
    .menu_phone{
        width: 130px;
    }
    .menu_phone:before{
        display: none;
    }
    .menu .mp_loc{
        display: none;
    }
    .menu.menu_scrool .mp_wrap{
        padding: 0;
        padding-right: 10px;
    }
    .menu .mp_wrap{
        display: inline-block;
        padding: 0;
        padding-right: 10px;
    }
    .mp_wrap{
        height: 50px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-evenly;
        flex-direction: column;
    }
    .mp_number:before {
        width: 15px;
        height: 15px;
        left: 0px;
    }
    .mp_number a {
        padding: 0;
        font-size: 12px;
        display: block;
        width: 100%;
        margin: 0;
        line-height: 2;
        text-align: right;
    }
    
    .menu.menu_scrool .nav_menu{
        padding: 0;
        margin: 0 auto;
    }
    .menu.menu_scrool .nav_menu a {
        color: #fff;
    }
    .menu .nav_menu a {
        color: #fff;
    }
    .nav_menu a + a{
        margin-left: 0;
        margin-top: 24px;
    }

    .large_yard{
        padding-top: 50px;
    }
    .ct_main {
        max-width: 100%;
        padding: 0 10px;
    }
    /* logo{display: none;} */
    logo{
        padding-top: 4px;
    }
    logo img {
        width: 56px;
    }
    .menu.menu_scrool logo img {
        width: 56px;
    }
    logo:before{
        display: none;
    }
    .mp_number {
        width: 100%;
        display: block;
    }
    .mp_btn {
        margin-top: 0;
        line-height: 2;
        font-size: 10px;
        width: 120px;
    }
    .menu header.top_bar{
        background: rgba(255,255,255, .7);
    }
    .menu_sandwich{
        display: block;
        width: 50px;
        height: 50px;
        background: #168291 url(../images/menu_sandwich.svg)no-repeat center center;
        background-size: 54%;
    }
    .menu_sandwich.opened{
        opacity: 0;
    }
    .menu_closeform{
        display: block;
        position: absolute;
        background: url(../images/closeform.png) no-repeat center center;
        -webkit-background-size: cover;
        background-size: 100%;
        width: 26px;
        height: 26px;
        top: 12px;
        left: 12px;
        z-index: 999999;
    }
    .main_screen{
        flex-direction: column;
        /* justify-content: flex-end; */
    }
    .ms_list.ct_main{
        /* position: relative; */
        position: absolute;
        bottom: 0;
        left: auto;
        transform: none;
        padding-left: 50px;
        margin-top: 12px;
    }
    .project_financing {
        position: relative;
        width: 240px;
        height: 66px;
    }
    .ah_card{
        padding-left: 30px;
    }
    .ah_card span{
        font-size: 16px;
    }
    .ahc_img{
        width: 50px;
        height: 50px;
        left: -10px;
    }
    .ahc_img:before{
        width: 50px;
        height: 50px;
    }
    .ahc_img:after{
        width: 50px;
        height: 50px;
    }
    .ah1 .ahc_img:after{
        background-size: 30px;
    }
    .ah2 .ahc_img:after{
        background-size: 30px;
    }
    .ah3 .ahc_img:after{
        background-size: 28px;
    }
    .ah4 .ahc_img:after{
        background-size: 28px;
    }
    .ah5 .ahc_img:after{
        background-size: 28px;
    }
    .ah6 .ahc_img:after{
        background-size: 24px;
    }
    .ah7 .ahc_img:after{
        background-size: 22px;
    }
    .ah8 .ahc_img:after{
        background-size: 26px;
    }


    .ah1:hover .ahc_img:after{
        background-size: 30px;
    }
    .ah2:hover .ahc_img:after{
        background-size: 30px;
    }
    .ah3:hover .ahc_img:after{
        background-size: 28px;
    }
    .ah4:hover .ahc_img:after{
        background-size: 28px;
    }
    .ah5:hover .ahc_img:after{
        background-size: 28px;
    }
    .ah6:hover .ahc_img:after{
        background-size: 24px;
    }
    .ah7:hover .ahc_img:after{
        background-size: 22px;
    }
    .ah8:hover .ahc_img:after{
        background-size: 26px;
    }
    .ah_card {
        background: rgb(31, 152, 169, .9);
        padding: 20px;
        width: 50%;
        justify-content: flex-start;
        height: 166px;
    }
    .ah_card:hover {
        border: transparent;
        border-bottom: 1px solid rgb(255, 255, 255, .3);
        border-right: 1px solid rgb(255, 255, 255, .3)
    }
    .ah_card+.ah_card+.ah_card+.ah_card+.ah_card{
        border-bottom: 1px solid rgb(255, 255, 255, .3);
    }
    .ah_card span{
        opacity: 1;
        font-size: 12px;
        top: 0;
    }
    .large_yard .title_main{
        display: block;
        width: 100%;
    }
    .ly_title_info {
        display: block;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 12px;
        padding-left: 80px;
    }
    .ly_title_info:after {
        width: 100%;
    }
    .ly_title_info:before {
        width: 156px;
        height: 134px;
        left: -27px;
        top: -13px;
    }
    .ly_ico{
        width: 20px;
        height: 20px;
    }
    .ly_ico img {
        width: auto!important;
        max-width: 68%;
    }
    .ly_ico span{
        width: 150px;
        font-size: 8px;
        padding-left: 20px;
        padding-right: 10px;
        height: 100%;
    }
    .ly_ico span br{
        display: none;
    }
    .no_dark_places {
        width: 300px;
        left: 50%;
        bottom: -29px;
        transform: translateX(-50%);
    }
    .no_dark_places span {
        width: auto;
        font-size: 14px;
        line-height: 29px;
    }
    .no_dark_places span:before {
        width: 120px;
        height: 120px;
        left: -16px;
    }

    .title_main{
        text-align: center;
        padding-left: 0;
    }
    .title_main p{
        font-size: 16px;
    }
    .excurs .ct_main:before{
        display: none;
    }
    .ex_btn{
        margin: 0 auto;
        margin-top: 12px;
    }
    .loc{
        padding-bottom: 50px;
    }
    .transport_list{
        flex-direction: column;
    }
    .tl_in_main{
        width: 300px;
    }
    .tl_in_main:before {
        width: 100%;
        height: 106px;
        top: -76px;
        z-index: 1;
    }
    .transport_list{
        margin-top: 100px;
    }
    .tl_in_main .tl_ar_dec {
        right: auto;
        top: 100%;
    }
    .tl_in_main .tl_ar_dec {
        border: 150px solid transparent;
        border-top: 40px solid #e5cbb0;
    }
    .tl_in{
        margin-left: 0;
        min-width: 1px;
        width: 300px;
        height: 100px;
        margin-top: 40px;
    }
    .tl_in:before {
        border-left: 150px solid #f3f5f7;
        border-top: 40px solid transparent;
        border-bottom: 0;
        border-right: 0;
        bottom: 100%;
        top: auto;
        left: 0;
    }
    .tl_in:after {
        border-right: 150px solid #f3f5f7;
        border-top: 40px solid transparent;
        border-bottom: 0;
        bottom: 100%;
        top: auto;
        left: auto;
        right: 0;
    }
    .tl_in+.tl_in{
        margin-left: 0;
        margin-top: 46px;
    } 
    .tl_ar_dec {
        border: 150px solid transparent;
        border-top: 42px solid #f3f5f7;
        top: 100%;
        right: auto;
        left: 0;
    }
    .infra_text{
        text-align: center;
    }
    .infra .ct_main{
        padding-left: 10px;
    }
    .download_plan {
        display: block;
        margin: 0 auto;
        margin-top: 50px;
        box-sizing: border-box;
        padding-left: 104px;
        text-align: left;
        padding-right: 10px;
        font-size: 14px;
    }
    .download_plan:before{
        left: -50px;
    }
    .plan_nav{
        padding: 0;
        margin-top: 50px;
        text-align: center;
    }
    .plan_nav a{
        margin: 4px;
    }
    .plan_nav a+a{
        margin: 4px;
    }
    .plan_slid{
        width: 300px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .ps_inn{
        border: 1px solid rgb(227, 227, 227, 1);
    }
    .fin_apartments .ct_main{
        justify-content:    center;
        flex-direction: column;
    }
    .fin_apartments .title_main {
        width: 100%;
        display: block;
        padding: 0;
    }
    .fin_variants {
        width: 100%;
    }
    .fin_sub_tit {
        font-size: 12px;
        width: 300px;
        margin: 0 auto;
        padding-left: 94px;
        margin-top: 40px;
        box-sizing: border-box;
        text-align: left;
    }
    .fin_variants {
        margin-top: 50px;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    .fin_in{
        width: 32%;
    }
    .repair_apart{
        margin-top: 0;
        margin-left: 1%;
    }
    .get_flat{
        /* margin-top: 40px; */
        padding-top: 40px;
    }
    .get_flat . title_main {
        font-size: 22px;
    }
    .final{
        margin-left: 1%;
    }
    .gf_sub_tit{
        padding-left: 0;
        margin-top: 20px;
    }
    .gf_sub_tit:before{
        display: none;
    }
    .gf_param{
        width: 300px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 auto;
    }
    .gf_param .gfp_in {
        min-width: 1px;
        width: 100%;
        margin-top: 12px;
    }
    .gf_nav{
        text-align: center;
        margin-top: 0;
    }
    .gf_nav a{
        margin: 4px;
    }
    .gf_param .gfp_in:nth-child(2){
        margin-bottom: 40px;
    }
    .how_buy .ct_main{
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .hb_sub_tit {
        margin-top: 36px;
        text-align: left;
    }
    .hb_variants {
        width: 100%;
        margin-top: 40px;
    }
    .cp_info {
        padding: 40px 20px;
    }


    .online_hds {
        display: block;
        margin: 0 auto;
        margin-top: 50px;
        box-sizing: border-box;
        padding-left: 52px;
        text-align: center;
        padding-right: 10px;
        font-size: 14px;
        float: none;
    }
    .online_hds:before{
        left: -50px;
    }
    .hod_str{
        width: 300px;
        margin: 0 auto;
        padding-top: 40px;
    }
    .hds_nav{
        margin-top: 20px;
    }
    .hds_year{
        display: block;
    }
    .hds_month{
        display: block;
        margin: 0 auto;
        margin-top: 12px;
    }
    .dev_side{

        display: block;
        max-width: 300px;
        margin: 0 auto;
    }
    .dev_side > img{
        display: none;
    }
    .dev_list span{
        width: 100%;
    }
    .dev_list span+span{
        margin-top: 24px;
    }
    .develop .ct_main .dev_side > p{
        font-size: 16px;
    }
    .develop .ct_main .dev_side > p br{
        display: none;
    }
    .dev_list span+span+span{
        margin-top: 24px;
    }
    .develop .ct_main{
        padding-left: 10px;
    }
    .project_dec_btn{
        margin-top: 36px;
        text-align: left;
    }
    .cont_info {
        display: block;
        padding: 26px 10px;
        width: 100%;
        float: none;
    }
    .cont_map{
        position: relative;
        width: 100%;
        height: 400px;
    }
    .footer .ct_main {
        justify-content: center;
        flex-direction: column;
    }
    .f_center{
        margin: 18px auto;
    }
    .f_right{
        text-align: center;
    }
    .f_center span br{
        display: none;
    }
    .cont_list {
        max-width: 300px;
        margin: 0 auto;
        margin-top: 14px;
    }
    .ctl_text {
        margin-left: 8px;
        font-size: 13px;
    }
    .cnt_btn {
        height: auto;
        padding: 12px 0px;
        position: relative;
        width: 100%;
        margin-top: 22px;
    }

    .popup_main {
        max-width: 300px;
        padding: 30px 10px;
    }
    .pu_tm span:before{
        display: none;
    }
    .closeform {
        width: 18px;
        height: 18px;
        top: 10px;
        right: 10px;
    }
    .pu_form{
        margin-top: 20px;
    }
    .pu_tm{
        text-align: center;
        font-size: 26px;
    }
    .pu_form .form_in.btn_style{
        margin-top: 30px;
    }
    .thx_sub_tit{
        font-size: 12px;
        margin-top: 18px;
    }
    .thx_sub_tit:before {
        width: 70px;
        height: 65px;
        left: 19px;
        background-size: 100%;
    }
    .pu_area span{
        text-align: center;
    }
    .pu_area span+span{
        margin: 0 auto;
    }
    .rooms_area{
        display: block;
        padding-left: 0;
        text-align: center;
        margin-top: 18px;
    }
    .rooms_area:before{
        display: none;
    }
    .flat_img {
        display: flex;
        width: 100%;
        height: 300px;
        float: none;
        margin-top: 12px;
    }
    .flat_img img{
        max-height: 100%;
    }
    .form_tit{
        font-size: 16px;
        text-align: center;
    }
    .pu_fin_list{
        margin-top: 14px;
    }
    .pu_fin_list li{
        display: block;
        width: 100%;
        margin-top: 12px;
    }
    .pu_fin .pu_form .form_in{
        display: block;
        width: 100%;
    }
    .pu_fin .pu_form .btn_style {
        margin-left: 0;
    }
    .pu_fin .pu_form .form_in+.form_in{
        margin-left: 0;
        margin-top: 12px;
    }
    .banks_list img{
        max-width: 50%;
    }
    .banks_list{
        justify-content: center;
    }
    /* .social_media {
        position: relative;
        width: 100%;
        background: #fcfdfe;
        padding-bottom: 26px;
    }
    .social_media:before{
        display: none;
    }
    .social_media a {
        position: relative;
        padding: 10px;
        border-radius: 7px;
        background-color: #168291;
        background-size: 50%;
    } */
    .ms_inner{
        padding-bottom: 100px;
    }


    .pu_sale{
        padding: 0;
    }
    .ps_lside {
        padding: 30px 10px;
        float: none;
        width: 100%;
    }
    .ps_rside {
        display: block;
        float: none;
        width: 100%;
        font-size: 0;
    }
    .popup_action {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 40px;
    }

    .popup_action_title {
        font-size: 42px;
        line-height: 120%;
    }

    .popup_action_form {
        margin-top: 42px;
    }
}
@media screen and (max-width: 850px){
    .head_plashka_top {
        width: 180px;
        margin-top: 20px;
    }
    .popup_action_img {
        max-width: 50vw;
    }
    .sub_podmena {
        font-size: 24px;
    }
    h1 {
        font-size: 42px;
    }
    sub {
        font-size: 14px;
    }
    .apart_info_box {
        width: 100px;
        height: 100px;
    }
    .apart_info_box span{
        font-size: 12px;
    }
    .apart_info_box b{
        font-size: 42px;
    }
    .apart_info_box l{
        font-size: 12px;
    }
    .msl_el + .msl_el {
        margin-left: 24px;
    }
    .msl_el span {
        margin-left: 10px;
        font-size: 12px;
    }
    .project_financing {
        /* position: absolute; */
        width: 240px;
        height: 66px;
        bottom: 0;
        right: auto;
        left: auto;
    }
    .ms_list.ct_main{
        /* padding-bottom: 130px; */
    }
    .msl_el img {
        width: 26px;
    }
    .next_bl_ar{
        display: none;
    }
    h1:before {
        background-size: 100%;
        width: 30px;
        height: 22px;
        top: -19px;
    }
    .title_main span:before{
        background-size: 100%;
        width: 30px;
        height: 22px;
        top: -19px;
        left: -3px;
    }
    /* .social_media a {
        width: 12px;
        height: 12px;
    }
    .social_media a+a {
        margin-top: 14px;
    } */
    .infra_text{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
    }
    .infra_text br{
        display: none;
    }
    .fin_in{
        width: 49%;
        margin: .5%;
    }
    .hb_variants{
        margin-top: 50px;
        flex-direction: column;
    }
    .hb_in{
        width: 100%;
    }
    .hb_in+.hb_in{
        margin-top: 10px;
    }
    .cp_info{
        width: 100%;
        text-align: center;
        float: none;
    }
    .cp_img{
        position: relative;
        width: 100%;
        height: 90vw;
    }
    .cp_info > p{
        max-width: 100%;
    }
    .cpi_list{
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
        text-align: left;
    }
}
@media screen and (max-height: 768px) and (max-width: 850px){
    .main_screen {
        /* overflow: hidden;
        background-size: 1800px;
        background-position: right -950px top; */
    }
}
@media screen and (max-width: 768px){
    .container_main.App .marquiz__button_fixed {
        height: 55px;
    }
    .sale_left_btn{
        top: 50%;
    }
}
@media screen and (max-width: 730px){
    .urban_ico {
        width: 60px;
        top: -90px;
        z-index: 1;
    }
    sub span {
        font-size: 20px;
    }
    .project_financing{
        z-index: 2;
        right: 20px;
    }
    .head_plashka {
        left: 110px;
        top: -79px;
        width: 110px;
    }
    /* .main_screen {
        overflow: hidden;
        background-size: 1550px;
        background-position: right -808px top;
    } */
    
    .ms_inner:before{
        display: none;
    }
    .ms_list:before {
        display: block;
        /* display: none; */
        position: absolute;
        content: '';
        width: 2px;
        height: 1px;
        background: initial;
        box-shadow: 0px 0px 110px 200px rgb(0 0 0 / 30%);
        top: 100%;
        left: 34%;
        opacity: 1;
        border-radius: 50%;
        transform: translate(-50%, 0%) scale(1.5, 1);
        z-index: 1;
        transition: .3s;
    }
    .ndp_sub_text {
        width: 196px;
        height: auto;
        left: auto;
        right: 0;
        top: 100%;
        font-size: 8px;
        transform-origin: center top;
        transform: none;
        transform: scaleY(0);
    }
    .no_dark_places{
        z-index: 1;
    }
    .rooms_area{
        margin-top: 6px;
        font-size: 12px;
    }
    .pu_area{
        font-size: 14px;
    }
    .flat_img{
        height: 200px;
        margin-top: 8px;
    }
    .pu_flat .pu_form{
        margin-top: 14px;
    }
    .form_tit{
        font-size: 12px;
        margin-bottom: 8px;
    }
    .pu_form .form_in + .form_in {
        margin-top: 6px;
    }
    .pu_form .form_in.btn_style {
        margin-top: 14px;
    }
    
    .lg-outer {
        height: calc(100vh - 50px)!important;
        top: auto!important;
        bottom: 0!important;
    }
    .ly_ico:hover{
        z-index: 1111;
    }
    .ly_ico img{
        max-width: 50%;
    }
    .ly_ico.summerhouse span {
        transform-origin: center right;
        padding-right: 12px;
        padding-left: 4px;
        left: auto;
        right: 50%;
    }
    .ly_ico.walking_area span {
        transform-origin: center right;
        padding-right: 12px;
        padding-left: 4px;
        left: auto;
        right: 50%;
    }
    .ly_ico.kids span {
        transform-origin: center right;
        padding-right: 12px;
        padding-left: 4px;
        left: auto;
        right: 50%;
    }
    .ly_ico.pet span {
        transform-origin: center right;
        padding-right: 12px;
        padding-left: 4px;
        left: auto;
        right: 50%;
    }
    .ly_ico.park span {
        transform-origin: center right;
        padding-right: 12px;
        padding-left: 4px;
        left: auto;
        right: 50%;
    }
    .ms_list.ct_main {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .msl_el{
        display: block;
        position: relative;
        z-index: 2;
        width: 50%;
    }
    .msl_el + .msl_el{
        margin-left: 0;
    }
    /* .ms_inner:before {
        display: block;
        position: absolute;
        content: '';
        width: 2px;
        height: 1px;
        background: initial;
        box-shadow: 0px 0px 110px 200px rgb(0 0 0 / 27%);
        top: 88%;
        left: 34%;
        opacity: 1;
        border-radius: 50%;
        transform: translate(-50%, 0%) scale(1.5, 1);
        z-index: 1;
        transition: .3s;
    } */
    h1{
        position: relative;
        z-index: 11;
    }
    sub{
        position: relative;
        z-index: 11;
        color: #fff;
    }
    .apart_info_box{
        position: relative;
        z-index: 11;
    }
    .ms_list.ct_main{
        /* position: relative; */
        padding-left: 30px;
        z-index: 11;
    }
    .ms_inner.ct_main {
        padding-left: 30px;
    }

    .main_screen {
        min-height: 470px;
        padding-bottom: 70px;
    }

}
@media screen and (max-width: 650px){
    .popup_action_img {
        max-width: 230px;
    }
    .pu_action_list {
        display: block;
        margin-left: 0;
    }
    .pu_action_list > li {
        padding-left: 0;
        width: 100%;
    }

    .pu_action_list > li + li {
        margin-top: 15px;
    }

    .pu_action {
        max-width: 300px !important;
        margin: 0 auto !important;
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }
    .gal_nav {
        position: relative;
        bottom: auto;
        top: -4px;
        width: 100%;
        height: 60px;
    }
    .container_main.App .gal_slid .slick-next, .container_main.App .gal_slid .slick-prev {
        bottom: -37px;
    }
    .container_main.App .gal_slid .slick-prev {
        left: 10px;
        transform: none;
    }
    .container_main.App .gal_slid .slick-next {
        right: 10px;
        transform: none;
    }
    .loc .title_main br{
        display: block;
    }
    .excurs .ct_main{
        padding-top: 32px;
    }
    .excurs .title_main{
        font-size: 16px;
    }
    .title_main span:before{
        display: none;
    }
}
@media screen and (max-width: 780px) {
    .pal__name {
        font-size: 20px;
        font-weight: bold;
    }
    .pal__img {
        height: 250px;
    }
    .pal__img img {
        max-height: 250px;
    }
    .pal__info {
        font-size: 18px;
    }
    .pal__area {
        font-size: 18px;
    }
    /* .ws_fixed {
        right: 30px;
        bottom: 30px;
    } */
}
@media screen and (max-width: 740px) {
    .pu_action_list2 {
        display: block;
    }
    .pu_action_list2 > li {
        width: 100%;
    }
    .pal__item {
        border: 1px solid #eee;
        border-top-width: 3px;
    }
    .pu_action {
        max-width: 420px !important;
        margin: 0 auto !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }
    .pal__img {
        height: auto;
    }
    .pal__info {
        margin-top: 20px;
    }
    .pu_action_list2 > li + li .pal__info {
        display: none;
    }
    .pu_action_list2 > li + li {
        margin-top: 25px;
    }
    .pu_action {
        margin-left: 15px;
        margin-right: 15px;
    }
    .fin_apartments_r {
        display: none;
    }
    .fin_apartments_l {
        width: 100%;
    }
    .fin_apartments__inner {
        display: block;
    }
    .fin_sub_list, .apartments__form {
        padding-left: 0;
    }
    .presentation .title_main{
        font-size: 16px;
    }
    .presentation .ly_sub_tit {
        font-size: 12px;
        margin-top: 12px;
    }
    .pp_inner {
        padding-top: 20px;
    }
}
@media screen and (max-width:  580px) {
    .popup_action_img {
        display: none;
    }
    .kachestvo__list > li {
        width: 50%;
    }
    .title__dop span {
        display: block;
        max-width: 300px;
    }
    .title__dop br {
        display: none;
    }
} 
@media screen and (max-width: 560px){
    .main_screen {
        padding-top: 0;
    }
    .sub_podmena {
        font-size: 20px;
    }
    .plan_nav_mob_hide {
        display: none !important;
    }
    .plan_nav a {
        font-size: 20px;
    }
    h1 {
        font-size: 30px;
    }
    sub {
        font-size: 20px;
    }
    sub br{
        /* display: none; */
    }
    .pu_fin_list li > span{
        font-size: 14px;
        padding-left: 24px;
        margin-top: 8px;
    }
    .pu_fin_list > span{
        font-size: 18px;
        text-align: center;
    }
    .pu_fin_list li > span br{
        display: none;
    }
    .form_tit{
        font-size: 16px;
    }
    .pu_fin_list li span:before{
        width: 18px;
        height: 18px;
    }


    .msl_el img{
        font-size: 22px;
    }
    .msl_el span {
        margin-left: 6px;
        font-size: 10px;
    }
    .ly_title_info:before{
        display: none;
    }
    .ly_title_info{
        padding: 12px;
        text-align: justify;    
    }
    .ly_title_info span {
        font-size: 14px;
    }
    .title_main{
        font-size: 30px;
        padding-left: 0;
    }
    .ly_sub_tit{
        font-size: 12px;
    }
    .loc_in{
        width: 100%;
    }
    .loc_in+.loc_in{
        flex-direction: column;
    }
    .infra_in{
        width: 50%;
    }
    .infra_in+.infra_in+.infra_in{
        margin-top: 14px;
    }
    .fin_in{
       width: 100%;
       margin: 0 auto;
    }
    .fin_in+.fin_in{
        margin-top: 10px;
    }
    .how_buy{
        padding: 30px 0px ;
    }
    .develop {
        padding-top: 14px;
        padding-bottom: 40px;
    }
    
    .main_screen{
        background-position: top left -110px;
    }
}
@media screen and (max-width: 480px){
    .ms_other_href a + a {
        margin-left: 20px;
    }
    .ms_other_title {
        font-size: 22px;
    }
    .ms_other_href {
        margin-top: 20px;
    }
    .sale_left_btn {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100px;
        left: -36px;
        height: 30px;
        line-height: 30px;
    }
    .popup_action_form {
        width: 100%;
        max-width: 100%;
    }
    .popup_action {
        padding-left: 30px;
        padding-right: 30px;
    }
    .popup_action_title {
        font-size: 28px;
    }
    .popup_action_form {
        margin-top: 22px;
    }
    .sub_podmena {
        color: #fff;
    }
    .hds_month a {
        font-size: 20px;
    }
    .hds_month a+a {
        margin-left: 17px;
    }
    .bus_stop{
        padding-left: 116px;
        width: 300px;
        box-sizing: border-box;
    }
    .bus_stop:before{
        left: -36px;
    }
    .infra_in{
        /* width: 100%; */
        align-items: center;
        margin-bottom: 20px;
    }

    .infra_in+.infra_in{
        /* margin-top: 20px; */
    }
    .infra_in span{
        font-size: 12px;
        margin-top: 6px;
        text-align: center;
    }
}
@media screen and (max-width:  420px) {
    .sub_podmena br {
        display: none;
    }
    .special_offer .form_title {
        font-size: 20px;
    }
    /* .ws_fixed {
        right: 20px;
        bottom: 20px;
    } */
        .ws_fixed img {
            max-width: 50px;
        }
        .ws_fixed {
            right: 12px;
        }
        .cbk-phone.callbackkiller-mobile, .cbk-phone.callbackkiller-mobile:hover {
            right: 20px!important;
        }
    .how_buy .title_main {
        width: auto;
        min-width: 20px;
    }
    .hb_sub_tit br {
        display: none;
    }
    .hb_in {
        margin-top: 0 !important;
        margin-bottom: 10px !important;
        padding: 20px 12px 20px 22px;
        height: 168px;
    }
    .hb_in a {
        margin-top: 17px;
    }
    .apartments__form_title {
        font-size: 18px;
    }
    .apartments__form_inner {
        margin-top: 15px;
    }
    .apartments__form {
        margin-top: 39px;
    }
    .fin_apartments_l {
        padding-bottom: 0;
    }
    .kachestvo .title_main span {
        text-align: center;
    }
    .fin_apartments {
        margin-top: 50px;
    }
    .title__dop span {
        max-width: 220px;
    }
    .kachestvo__list > li {
        padding-left: 10px;
        margin-bottom: 30px;
    }

    .kachestvo__list {
        margin-left: -10px;
        margin-bottom: -30px;
    }
    .kachestvo__list > li span {
        font-size: 14px;
        line-height: 14px;
    }
    .title__dop {
        font-size: 14px;
    }
}
@media screen and (max-width:  360px) {
    .popup_action {
        padding-left: 20px;
        padding-right: 20px;
    }
    .plan_nav a+a {
        margin-left: 0;
        margin-right: 0;
    }
    .plan_nav {
        margin-left: -10px;
        margin-right: -10px;
    }
    .title__dop {
        font-size: 13px;
    }
    .title__dop img {
        margin-right: 14px;
        width: 76px;
    }
    .kachestvo__list {
        margin-top: 40px;
    }
    .kachestvo {
        margin-top: 40px;
    }
}
/*@media*/